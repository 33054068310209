import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColleaguesService } from '../colleagues.service';
import { ActivatedRoute, Router } from "@angular/router"; // ActivatedRoue is used to get the current associated components information.
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-edit-colleague',
  templateUrl: './edit-colleague.component.html',
  styleUrls: ['./edit-colleague.component.css']
})
export class EditColleagueComponent implements OnInit {

  public ColleagueForm: FormGroup;  // Define FormGroup to travcol's edit form
  localUserData: LocalUser;


  dropdownSettings: any = {};
  realRoleListAdmin = [{ id: '1', realrole: 'Invité' }, { id: '2', realrole: 'Utilisateur' }, { id: '3', realrole: 'Administrateur' }];
  realRoleListSuperAdmin = [{ id: '1', realrole: 'Invité' }, { id: '2', realrole: 'Utilisateur' }, { id: '3', realrole: 'Administrateur' }, { id: '4', realrole: 'Super-Zéro' }];
  public realRoleList: any;
  selectedItems = [];
  closeModal: string;
  enabledQuota: boolean;
  savedQuota: string;
  enableRoleField: boolean;


  constructor(
    private colleaguesApi: ColleaguesService,       // Inject CRUD API in constructor
    private fb: FormBuilder,            // Inject Form Builder service for Reactive forms
    private actRoute: ActivatedRoute,   // Activated route to get the current component's inforamation
    private router: Router,             // Router service to navigate to specific component
    private modalService: NgbModal,

  ) {//console.log(Date() ,'constructor')
  }


  ngOnInit() {
    //console.log(Date() ,'ngoninit')



    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.localUserData.role > 2 ? {} : this.router.navigate(['Welcome'])
    }

    this.updateAllColleagueData();   // Call updateStudentData() as soon as the component is ready 
    const id = this.actRoute.snapshot.paramMap.get('id');  // Getting current component's id or information using ActivatedRoute service
    this.colleaguesApi.GetColleague(id).valueChanges().subscribe(data => {

      if (Number(this.localUserData.role) < Number(data.role)) this.router.navigateByUrl('welcome')

      this.ColleagueForm.controls['firstName'].setValue(data.firstName)  // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
      this.ColleagueForm.controls['lastName'].setValue(data.lastName)  // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
      this.savedQuota = this.ColleagueForm.controls['quota'].value;
      this.ColleagueForm.controls['quota'].setValue(data.quota)  // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
      this.ColleagueForm.controls['emailAddress'].setValue(data.emailAddress)
      if (data.role == 1) {
        this.enabledQuota = false;
      }
      else {
        this.enabledQuota = true;
      }// Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
      if (data.role != "1,5") {
        this.ColleagueForm.controls['role'].setValue(this.realRoleListSuperAdmin[data.role - 1]['realrole'])  // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
        this.selectedItems = [
          { id: data.role.toString(), realrole: this.realRoleListSuperAdmin[data.role - 1]['realrole'] }
        ];
        this.enableRoleField = true
      }
      else {
        this.enableRoleField = false
      }

    })

    this.colleaguesApi.localUserData.role
    if (this.colleaguesApi.localUserData.role == 3) {
      this.realRoleList = this.realRoleListAdmin;
    }
    else if (this.colleaguesApi.localUserData.role == 4) {
      this.realRoleList = this.realRoleListSuperAdmin;
    }



    this.dropdownSettings = {
      "singleSelection": true,
      "defaultOpen": false,
      "idField": "id",
      "textField": 'realrole',
      "selectAllText": "Select All",
      "unSelectAllText": "UnSelect All",
      "enableCheckAll": false,
      "itemsShowLimit": 12,
      "allowSearchFilter": true,
      "searchPlaceholderText": 'Rechercher un rôle',
      "noDataAvailablePlaceholderText": 'Pas de données disponibles',
      "closeDropDownOnSelection": true,


    };
  }

  onItemSelect(item: any) {

    if (item.id == 1) {
      this.savedQuota = this.ColleagueForm.controls['quota'].value;
      this.enabledQuota = false;
      this.ColleagueForm.controls['quota'].setValue('NA');
    }
    else {
      this.enabledQuota = true;
      this.ColleagueForm.controls['quota'].setValue(this.savedQuota)

    }

  }


  // Accessing form control using getters
  get firstName() {
    //console.log(Date() ,this.ColleagueForm.get('firstName'))
    return this.ColleagueForm.get('firstName');
  }

  get lastName() {
    return this.ColleagueForm.get('lastName');
  }

  get emailAddress() {
    return this.ColleagueForm.get('emailAddress');
  }

  get role() {
    return this.ColleagueForm.get('role');
  }

  get quota() {
    return this.ColleagueForm.get('quota');
  }

  get isFavoriteOf() {
    return this.ColleagueForm.get('isFavoriteOf');
  }

  updateAllColleagueData() {
    this.ColleagueForm = this.fb.group({

      emailAddress: ["", [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-zA-Z\-0-9]+[\.]+[a-zA-Z]{2,}$')]],
      firstName: [''],
      //firstName: ['',[Validators.required,Validators.pattern('^\b(?:[A-Z]\w+\b(?:\s*\-*)?)$')]],
      lastName: [''],
      role: [''],
      quota: ['', [Validators.pattern('^(NA|[0-9]+)$')]],

    })
  }



  // Go back to previous component

  onBack() {
    this.router.navigate(['admin-colleagues-list']);

  }


  ResetForm() {
    this.ColleagueForm.reset();
  }

  // Below methods fire when somebody click on submit button
  updateForm() {



    this.ColleagueForm.controls['firstName'].setValue(this.ColleagueForm.controls['firstName'].value.replace(/(^\w{1})|(\s{1}\w{1})|(-\w{1})/g, match => match.toUpperCase()));

    this.ColleagueForm.controls['lastName'].setValue(this.ColleagueForm.controls['lastName'].value.toUpperCase());
    this.ColleagueForm.controls['role'].setValue(this.ColleagueForm.controls['role'].value['0'].id);
    this.colleaguesApi.UpdateColleague(this.ColleagueForm.value);
    //console.log(this.sort)
    this.router.navigate(['admin-colleagues-list']);               // Navigate to travcol's list page when travcol data is updated
  }


  triggerModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), error.message)
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onBlock() {
    this.ColleagueForm.controls['role'].setValue("1,5") //utilisateur sans photo
    this.colleaguesApi.UpdateColleague(this.ColleagueForm.value);
  }


  onNotBlock() {

    this.ColleagueForm.controls['role'].setValue("2") //remettre à utilisateur
    this.colleaguesApi.UpdateColleague(this.ColleagueForm.value);
  }

}