import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';  // Location service is used to go back to previous component

@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrls: ['./greetings.component.css']
})
export class GreetingsComponent implements OnInit {

  constructor(    
    private location: Location,         // Location service to go back to previous component
    ) { //console.log(Date() ,'constructor')
    }


    ngOnInit(): void {
     //console.log(Date() ,'ngoninit')
  }


  // Go back to previous component
  goBack() {
    this.location.back();
  }

}
