import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ColleaguesService } from 'src/app/components/colleagues/colleagues.service';
import { AuthService } from '../../boilerplate/auth.service';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ParametersService } from '../../others/parameters.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-add-colleague',
  templateUrl: './add-colleague.component.html',
  styleUrls: ['./add-colleague.component.css']
})
export class AddColleagueComponent implements OnInit {
  @ViewChild('MailNewColleague') mymodal: ElementRef;
  realRoleListAdmin = [{ id: '1', realrole: 'Invité' }, { id: '2', realrole: 'Utilisateur' }, { id: '3', realrole: 'Administrateur' }];
  realRoleListSuperAdmin = [{ id: '1', realrole: 'Invité' }, { id: '2', realrole: 'Utilisateur' }, { id: '3', realrole: 'Administrateur' }, { id: '4', realrole: 'Super-Zéro' }];
  public realRoleList: any;
  public ColleagueForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  dropdownSettings: any = {};
  localUserData: LocalUser;
  selectedItems = [];
  closeModal: string;
  enabledQuota: boolean = true;
  savedQuota: string;
  emailNewColleague: string;
  isLoading = false; // sending the post request async so it's in progress
  submitted = false; // hide the response message on multiple submits
  responseMessage: string; // the response message to show to the user
  //moderatorEmailAddress: any;
  colleagueInfo: any;
  realRole = { 1: "Invité", 2: "Utilisateur", 3: "Administrateur", 4: "Super-Zéro" }

  constructor(
    public colleagueApi: ColleaguesService,  // CRUD API services
    public fb: FormBuilder,       // Form Builder service for Reactive forms
    public toastr: ToastrService,  // Toastr service for alert message
    private router: Router,
    public authservice: AuthService,
    public parametersApi: ParametersService,
    private modalService: NgbModal,
    private http: HttpClient,
  ) {  //console.log(Date() ,'constructor')
  }


  ngOnInit(): void {


    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.localUserData.role > 2 ? {} : this.router.navigate(['Welcome'])

    }




    this.colleagueApi.GetColleaguesList();  // Call GetStudentsList() before main form is being called
    this.CoForm();
    this.colleagueApi.localUserData.role
    if (this.colleagueApi.localUserData.role == 3) {
      this.realRoleList = this.realRoleListAdmin;
    }
    else if (this.colleagueApi.localUserData.role == 4) {
      this.realRoleList = this.realRoleListSuperAdmin;
    }

    this.selectedItems = [
      { id: '2', realrole: 'Utilisateur' }
    ];
    this.dropdownSettings = {
      "singleSelection": true,
      "defaultOpen": false,
      "idField": "id",
      "textField": 'realrole',
      "selectAllText": "Select All",
      "unSelectAllText": "UnSelect All",
      "enableCheckAll": false,
      "itemsShowLimit": 12,
      "allowSearchFilter": true,
      "searchPlaceholderText": 'Rechercher un rôle',
      "noDataAvailablePlaceholderText": 'Pas de données disponibles',
      "closeDropDownOnSelection": true,


    };

    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      this.ColleagueForm.controls['quota'].setValue(data.payload.toJSON()['globalQuota'])
    })
    this.ColleagueForm.controls['emailAddress'].setValue('@' + environment.suffix)
  }

  onItemSelect(item: any) {

    if (item.id == 1) {
      this.savedQuota = this.ColleagueForm.controls['quota'].value;
      this.enabledQuota = false;
      this.ColleagueForm.controls['quota'].setValue('NA');
    }
    else {
      this.enabledQuota = true;
      this.ColleagueForm.controls['quota'].setValue(this.savedQuota)

    }



  }

  onSelectAll(items: any) {
    //console.log(Date() ,'onSelectAll', items);
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  // Go back to previous component

  onBack() {
    this.router.navigate(['admin-colleagues-list']);

  }



  CoForm() {
    this.ColleagueForm = this.fb.group({
      emailAddress: ["", [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-zA-Z\-0-9]+[\.]+[a-zA-Z]{2,}$')]],
      firstName: [''],
      //firstName: ['',[Validators.required,Validators.pattern('^\b(?:[A-Z]\w+\b(?:\s*\-*)?)$')]],
      lastName: [''],
      role: [''],
      quota: ['', [Validators.pattern('^(NA|[0-9]+)$')]],
    })
  }

  get emailAddress() {
    return this.ColleagueForm.get('emailAddress');
  }

  get firstName() {
    //console.log(Date() ,this.ColleagueForm.get('firstName'))
    return this.ColleagueForm.get('firstName');
  }

  get lastName() {
    return this.ColleagueForm.get('lastName');
  }



  get role() {
    return this.ColleagueForm.get('role');
  }

  get quota() {
    return this.ColleagueForm.get('quota');
  }

  ResetForm() {
    this.ColleagueForm.reset();
  }



  /*titlecaseAllWords(argument) {
      return argument.replace(/\w+/g, function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
    }*/


  submitColleagueData() {
    this.emailNewColleague = this.ColleagueForm.controls['emailAddress'].value
    this.ColleagueForm.controls['lastName'].setValue(this.ColleagueForm.controls['lastName'].value.toUpperCase());

    this.ColleagueForm.controls['firstName'].setValue(this.ColleagueForm.controls['firstName'].value.replace(/(^\w{1})|(\s{1}\w{1})|(-\w{1})/g, match => match.toUpperCase()));
    this.ColleagueForm.controls['role'].setValue(this.ColleagueForm.controls['role'].value['0'].id);
    this.colleagueApi.AddColleague(this.ColleagueForm.value, this.ColleagueForm.controls['emailAddress'].value.replace(/([.])/g, '*')); // Submit student data using CRUD API
    this.toastr.success(this.ColleagueForm.controls['firstName'].value + ' ' + this.ColleagueForm.controls['lastName'].value + ' a bien été ajouté.'); // Show success message when data is successfully submited
    //this.triggerModal(MailNewColleague)

    //You can NOT use this.mymodal at ngInit, the early time you can use is in ngAfterViewInit
    this.colleagueInfo = this.ColleagueForm.value
    this.triggerModal(this.mymodal);

    this.ResetForm();  // Reset form when clicked on reset button
    this.router.navigate(['admin-colleagues-list']);
  };


  triggerModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), error.message)
      });
  }

  private getDismissReason(reason: any): string {
    //console.log(reason)
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  submitMailNewColleagueData() {
    //console.log(this.colleagueInfo)
    this.ColleagueForm.disable(); // disable the form if it's valid to disable multiple submissions
    this.isLoading = true; // sending the post request async so it's in progress
    this.submitted = false; // hide the response message on multiple submits

    var formData: any = new FormData();
    formData.append("subject", "BossCool - Invitation à utiliser l'application");
    formData.append("firstName", this.colleagueInfo.firstName);
    formData.append("lastName", this.colleagueInfo.lastName);
    formData.append("emailAddress", this.colleagueInfo.emailAddress);


    let body =
      "Bonjour,\n" +
      "\n" +
      "Le modérateur de BossCool vient d'ajouter votre adresse email dans la liste des personnes autorisées.\n" +
      "\n" +
      `Dès maintenant, vous pouvez donc vous enregistrer dans BossCool.\n` +
      `Pour cela , veuillez suivre ce lien: "` + window.origin + `"\n` +
      "\n" +
      'Récapitulatif des encodages :\n' +
      "\n" +
      "Prénom : " + this.colleagueInfo.firstName + "\n" +
      "Nom : " + this.colleagueInfo.lastName + "\n" +
      "Quota : " + this.colleagueInfo.quota            + " \n" +
      "Rôle : " + this.realRole[this.colleagueInfo.role] + " \n" +
      "Adresse Email : " + this.colleagueInfo.emailAddress + " \n" +
      "\n" +
      "Bon travail." + "\n" +
      "Votre équipe BossCool" + "\n" +
      "\n" +
      "... ne malmenez pas trop Expresso l'escargot\n"

    formData.append("message", body);


    this.http.post("https://script.google.com/macros/s/AKfycbzpQpkA5zTCGf44hgd0Ts7yJApsWOOqlGqRcukc4Fuhx58W-xRC/exec", formData).subscribe(
      (response) => {
        // choose the response message
        if (response["result"] == "success") {
          this.responseMessage = "Le message a bien été envoyé au futur utilisateur.";
        } else {
          this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        }
        this.ColleagueForm.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        //console.log(response);
      },
      (error) => {
        this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        this.ColleagueForm.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        console.log(error);
      }
    );
  }





}









