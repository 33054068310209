<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom"
  style="margin-top: 100px; margin-bottom: 10px;">
  <h1 style="color:white;">Ajouter un travail collaboratif</h1>
  <!--   <p class="custom-text">A demo CRUD app for <strong>travcol record management system</strong> built with <strong>Angular7
        and Firebase5</strong></p>-->
</div>


<div *ngIf="!hasFavorites" class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div class="card_top center">
          <img src="assets/images/NoFavorites.png" width="60%" height="60%" class="nodata-msg" alt="">
        </div>
        <div class="card_bottom">
          <div class="card_info">
            <p>
              Bin Tin !! On ne peut vraiment rien vous demander hein ! Vous n'avez pas encore choisi vos collègues
              favori(te)s !<br>
              Pour cela:

            <ul style="padding-left:25px;margin-top: -15px;">
              <li>
                cliquez sur le bouton ci-dessous
              </li>
              <li>
                puis une fois dans la liste des collègues, cliquez sur les icones "favori" (les étoiles)
              </li>
              <li>
                puis revenez ajouter un travail collaboratif (menu Travaux -> Ajouter)
              </li>
            </ul>


          </div>
          <div class="card_creator">
            <button type="button" class="btn btn-primary" routerLink="/admin-colleagues-list">
              Liste des collègues
              <span class="icon-iconFavoris" style="font-size: 1.5em;"><span class="path1"></span><span
                  class="path2"></span><span class="path3"></span></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="hasFavorites" class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div style="text-align: right;margin-bottom: -20px;">
          <button  aria-label="." ngbTooltip="Aide" class="btn" (click)="triggerModal(modalDataAddTravCol)">
            <span class="icon-iconInfo" style="font-size: 1.4em;"></span>
          </button>
        </div>
        <div class="card_top center">

        </div>
        <div class="card_bottom" style="padding-bottom: 2px;padding-top: 2px;">
          <!--<div class="card_category">
              Inscription
            </div>-->
          <form [formGroup]="travcolForm" (ngSubmit)="submitTravColData()" novalidate>
            <div class="card_info" style="padding-top: 0px;margin-top: 0px;">


              <label>Date</label>
              <input placeholder="." type="date" formControlName="date" min={{minDate}} max={{strTodayDate}} class="formControl left " required>
              <!-- Showing errors using getter method -->
              <p *ngIf="date.touched && date.invalid" class="error"><sup>*</sup>Veuillez entrer une date valide</p>
              <p *ngIf="date.errors?.minlength" class="error"><sup>*</sup>Voyons ! Une date fait plus de 2
                caractères</p>
                <p *ngIf="date.errors?.minMaxDateValidator" class="error"><sup>*</sup>Veuillez entrer une date pour cette année scolaire</p>


              <label>Durée (en minutes)</label>
              <input aria-label="." type="text" formControlName="duration" class="formControl left" required>

              <!-- Showing errors using getter method -->
              <p *ngIf="duration.touched && duration.invalid" class="error"><sup>*</sup>Veuillez entrer une durée en
                minutes</p>
              <p *ngIf="duration.errors?.pattern" class="error"><sup>*</sup>Utilisez uniquement des chiffres</p>

              <label>Collègues</label>

              <!--<select multiple="yes" formControlName="city" class="formControl left" required>
                <option *ngFor="let allcolleague of AllColleagues" [value]="allcolleague.emailAddress">{{allcolleague.emailAddress}}</option>
             </select>
             
              <p *ngIf="colleague.touched && colleague.invalid" class="error"><sup>*</sup>Veuillez entrer dans un collègue</p> -->

              <div>

                <ng-multiselect-dropdown class="formControl left custom-font-size"
                  style="width:90%;display: inline-block;" required [placeholder]="'Choisir collègue(s)'"
                  [settings]="dropdownSettings" [data]="favorites" formControlName="colleaguesOfTravcol"
                  searchPlaceholderText='bouh' (onSelect)="onItemSelect($event)">
                  <ng-template #optionsTemplate let-item let-option="option" let-id="id">
                    <div style="display: flex;justify-content: space-between;">
                        <label style="color: #333; min-width: 130px; font-weight: normal;">{{option}}</label>
                        <mat-icon class="warning-icon" *ngIf="isFood(option)"
                            [matTooltip]="getTooltipText(option)" [matTooltipPosition]="'right'"
                            matTooltipClass="description-tooltip">warning</mat-icon>
                    </div>
                </ng-template>
                </ng-multiselect-dropdown>
                <span ngbTooltip="Favoris" routerLink="/colleagues-list"
                  style="font-size: 2em;margin-left: 10px;vertical-align: middle;cursor:pointer"
                  class="icon-iconFavoris"><span class="path1"></span><span class="path2"></span><span
                    class="path3"></span></span>
              </div>
              <!--ng-multiselect-dropdown class="dropdown" [placeholder]="'Choisissez un collègue'" [settings]="dropdownSettings"
              [data]="foodOptions" [(ngModel)]="selectedFood" [ngModelOptions]="{standalone: true}" onDropDownClose="onDropdownClose()"
 
              click="this.isDropdownOpen = true">
              <ng-template #optionsTemplate let-item let-option="option" let-id="id">
                  <div style="display: flex;justify-content: space-between;">
                      <label style="color: #333; min-width: 130px; font-weight: normal;">{{option}}</label>
                      <mat-icon class="warning-icon" *ngIf="isFood(option)"
                          [matTooltip]="getTooltipText(option)" [matTooltipPosition]="'right'"
                          matTooltipClass="description-tooltip">warning</mat-icon>
                  </div>
              </ng-template>
  </ng-multiselect-dropdown-->
              <!--ng-select 
              [multiple]="true"
              [items]="selectOptions" 
              formcontrol="colleaguesOfTravcol"
              name="licenseAuthorityId" 
              bindValue="customId" 
              bindLabel="customText" 
              placeholder="Select"
              required 
              [closeOnSelect]="false">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">

                      <input id="item-{{index}}" type="checkbox" name="item-{{index}}" [checked]="item$.selected"/> {{item.customText}}

                  </ng-template>
              </ng-select-->

              <div *ngIf="parameters['TCType']">
                <label>Type de TC</label>
                <input  aria-label="." type="text" formControlName="typeOfTC" class="formControl left">
                <p *ngIf="typeOfTC.touched && typeOfTC.invalid" class="error"><sup>*</sup>Veuillez entrer un type de
                  travail collaboratif</p>
              </div>
              <label>Objectifs</label>
              <input  aria-label="."type="text" formControlName="goals" class="formControl left">
              <p *ngIf="goals.touched && goals.invalid" class="error"><sup>*</sup>Veuillez entrer un objectif</p>

              <div *ngIf="parameters['TCDecisions']">
              <label>Décisions</label>
              <input aria-label="." type="text" formControlName="decisions" class="formControl left">
              <p *ngIf="decisions.touched && decisions.invalid" class="error"><sup>*</sup>Veuillez entrer une
                décision</p>
              </div>
                <!--div *ngIf="parameters['PDFJoin']">
               
                <input style="display: none" formControlName="PDFJoin"  accept="application/pdf" #PDFs type="file" (change)="detectFiles($event)">
                <label (click)="PDFs.click()" class="btn btn-primary" style="margin-right: 15px;">Joindre un PDF</label>
                <label *ngIf="!selectedFile" class="error" style="vertical-align: middle;"> <sup>*</sup>Veuillez joindre un PDF</label>
                <label *ngIf="selectedFile" style="vertical-align: middle; ">{{selectedFile.name}}</label>
              
                
              
              </div-->

        
   
            </div>
            <div class="card_creator">
              <button type="button" class="btn btn-danger " 
                style="border-radius:10px;padding: 10px;margin-top: 10px;margin-right: 30px;margin-bottom: 10px;"
                (click)="ResetForm()">Effacer
                <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>
              </button>

              <button type="submit" class="btn btn-primary"
                style="border-radius:10px;padding: 10px;margin-top: 10px; margin-bottom: 10px; position:absolute"
                [disabled]="!travcolForm.valid">
                <div class="button__progress progress-bar-striped progress-primary" [style.width]="(uploadProgress | async) + '%'" [attr.aria-valuenow]="(uploadProgress | async)" aria-valuemin="0" aria-valuemax="100"></div>
            
             
               <span *ngIf="(uploadProgress|async)<=0" class="button__text"> Valider</span> 
   
              
                <span *ngIf="(uploadProgress|async)>0&&(uploadProgress|async)<100"  class="button__text"> Chargement ... </span>
                <span *ngIf="(uploadProgress|async)==100" class="button__text"> Terminé !</span>
                
                <span class="icon-iconValider" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>
              </button>
          

            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>


<ng-template #modalDataAddTravCol let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Pas de panique !</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;margin-bottom: -20px;">

    C'est ici dans ce formulaire que vous consignez votre travail collaboratif. Il faut savoir que :
    <ul style="padding-left: 35px;">
      <li>le champ "durée" se remplit en minutes,</li>
      <li>il vous est possible d'ajouter d'autres collègues favoris en cliquant sur
        <span class="icon-iconFavoris" style="font-size: 1.5em;"><span class="path1"></span><span
            class="path2"></span><span class="path3"></span></span>,
      </li>
      <li>le bouton "Effacer" supprimera le contenu de tous les champs du formulaire.</li>
    </ul>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Fermer
      <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>
    </button>
  </div>

</ng-template>