import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../boilerplate/auth.service';
import { LocalUser } from '../../boilerplate/local-user';
import { ColleagueOfTravcol } from '../colleague-of-travcol';
import { TravcolsService } from '../travcols.service';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-all-travcols',
  templateUrl: './all-travcols.component.html',
  styleUrls: ['./all-travcols.component.css']
})
export class AllTravcolsComponent implements OnInit {

  p: number = 1;                      // Settup up pagination variable
  public allTravcols = [];                 // Save travcols data in travcol's array.
  public colleaguesOfTravcol: ColleagueOfTravcol[];
  hideWhenNoTravCol: boolean = false; // Hide travcols data table when no travcol.
  noData: boolean = false;            // Showing No travcol Message, when no travcol in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)
  closeModal: string;
  isOwnerOfTC: boolean = false;

  emailID: string;

  public search: string;
  public viewSearch = {
    "userEmailID": false,
    "initials": false,
    "fullName": false,
    "key": false,
    "date": false,
    "duration": false,
    "goals": false,
    "decisions": false,
    "isActivable" : false,
    "hasValidated" : false, 
    "hasActivated": false,
    "totalPeriods":false
  }
  //public colleaguesList: string
  hasValidatedFound: boolean = false;
  toBeDisplayed: boolean;
  localUserData: LocalUser; complement: any;
  totalPeriods: {}
  atLeastOneAcceptation: boolean = false;

// config tri par défaut
  mode: string = "asc";
  column: string = "userEmailID";
  type: string = "string";
  searchTerm={};
 

  constructor(

    public travcolsApi: TravcolsService, // Inject travcol CRUD services in constructor.

    public authservice: AuthService,

  ) { //console.log(Date() ,'constructor')
  }


  ngOnInit() {

    

    //console.log(Date() ,'ngoninit')
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)


      this.emailID = this.localUserData.emailID
    }
    let travcolList = this.travcolsApi.GetAllTravColsList();
    travcolList.valueChanges().subscribe(sData => {
      this.dataState(sData); // Initialize travcol's list, when component is ready

      this.allTravcols = [];
      this.totalPeriods = {}
      sData.forEach(sElement => {
        Object.values(sElement).forEach(ownerID => {
          Object.values(ownerID).forEach(travcol => {
            if (travcol.colleaguesOfTravcol[travcol.userEmailID] != undefined) {
              travcol.validated = travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated']
              travcol.activated = travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']
            }
            travcol.userEmailID = travcol.userEmailID.replaceAll("*", ".")
            travcol.key = travcol.key.replaceAll("-", "")
            if (travcol.isActivable && travcol.validated && travcol.activated) {
              if (this.totalPeriods[travcol.userEmailID] == undefined) {
                this.totalPeriods[travcol.userEmailID] = 0
              }
              this.totalPeriods[travcol.userEmailID] += (Number(travcol.duration) / 50)
              travcol.totalPeriods = this.totalPeriods[travcol.userEmailID]
            }
            else if (this.totalPeriods[travcol.userEmailID] == undefined) {
              travcol.totalPeriods = 0
            }
            else {
              travcol.totalPeriods = this.totalPeriods[travcol.userEmailID]
            }
            this.allTravcols.push(travcol)
          });
        });
      });
      //console.log(this.totalPeriods)
      //console.log(this.allTravcols)
      //console.log(this.complement)
      this.dataState(this.allTravcols)
    });
  }

  // Using valueChanges() method to fetch simple list of travcols data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState(data) {

    this.preLoader = false;
    if (data.length <= 0) {
      this.hideWhenNoTravCol = false;
      this.noData = true;
    } else {
      this.hideWhenNoTravCol = true;
      this.noData = false;
    }

  }


  setSortParams(param) {
    let vsKeys = Object.keys(this.viewSearch)
    vsKeys.forEach(element => {
      //console.log(element)
      if (element != param.column) {
        this.viewSearch[element] = false
      }

    });
    //console.log(param)
    this.mode = param.mode;
    this.column = param.column;
    this.type = param.type;
    //this.searchTerm = thi
  }

  startFilter(column) {
    let vsKeys = Object.keys(this.viewSearch)
    vsKeys.forEach(element => {
      //console.log(element)
      this.viewSearch[element] = false
    });
    //console.log(column)
    this.mode = ""
    this.column = column;
   
    this.viewSearch[column] = true

  }

  endFilter(column) {
    this.searchTerm[column] = ""
    this.viewSearch[column] = false

  }






  //vérifie si le user current est ben le propriétaire du travcol
  getmatching(travcol) {
    //console.log(Date() ,travcol)
    if (travcol.ownerID != null) {
      if (travcol.ownerID == this.emailID) {
        return this.isOwnerOfTC = true;
      }
      else {
        return this.isOwnerOfTC = false;
      }
    }
  }



}