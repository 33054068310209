<header class="page-header" id="header">
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom"
  style="margin-top: 100px; margin-bottom: 10px;">
  <h1 style="color: white; margin-right: 25px;">Liste de tous les travaux collaboratifs </h1>
  <!-- It won't show if there is no travcol data -->
</div>

<div class="pricing-header mx-auto">

  <!-- Preloader shows before the data loads-->
  <div class="no-data text-center" *ngIf="preLoader">
    <img src="assets/preloader.svg" class="preloader-icon" alt="No travcol">
  </div>

  <!-- No data shows when their is no travcol data available -->
  <div class="no-data text-center" *ngIf="noData">
    <img src="assets/images/NoTravcol2.png" width="30%" height="30%" class="nodata-msg" alt="">
    <p style="color: white; ">Pas de travail collaboratif à lister</p>
    <a *ngIf="localUserData &&!localUserData.isInvite" routerLink="/add-travcol" class="btn btn-secondary"
      style="margin-bottom: 25px;margin-top: -10px;">
      Ajouter un TC
      <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>

    </a>
  </div>


  <div class="wrapper" style="min-width: 100%;" *ngIf="hideWhenNoTravCol">
    <div class="cards_wrap" style="min-width: 100%;">
      <div class="card_item" style="min-width: 100%;margin-bottom: 20px;">
        <div class="card_inner" style="min-width: 100%;">

          <div class="card_top center" style="min-width: 100%;">
          </div>
          <div class="card_bottom" style="min-width: 100%;">
            <div class="card_info" style="min-width: 100%;">
              <!-- Showing travcols data -->
              <div style="overflow-x:auto" id="pdfTable">
                <table scrollx="true" style="margin-left:auto;margin-right:auto;min-width: 100%;margin-bottom: 20px;">

                  <tr>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['userEmailID']"> Email </span>
                      <span *ngIf="!this.viewSearch['userEmailID']" (click)="startFilter('userEmailID')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['userEmailID']" [(ngModel)]="searchTerm['userEmailID']" class="search"
                        placeholder="Chercher un email">


                      <span *ngIf="this.viewSearch['userEmailID']" (click)="endFilter('userEmailID')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'userEmailID', type: 'string', searchTerm: searchTerm['userEmailID']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='userEmailID' && mode =='asc')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='userEmailID' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'userEmailID', type: 'string', searchTerm: searchTerm['userEmailID']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='userEmailID' && mode =='dsc')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='userEmailID' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['initials']"> Initiales </span>
                      <span *ngIf="!this.viewSearch['initials']" (click)="startFilter('initials')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['initials']" [(ngModel)]="searchTerm['initials']" class="search"
                        placeholder="Chercher une initiale">


                      <span *ngIf="this.viewSearch['initials']" (click)="endFilter('initials')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'initials', type: 'string', searchTerm: searchTerm['initials']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='initials' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='initials' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'initials', type: 'string', searchTerm: searchTerm['initials']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='initials' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='initials' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>

                    <th class="center">
                      <span *ngIf="!this.viewSearch['fullName']"> Géniteur </span>
                      <span *ngIf="!this.viewSearch['fullName']" (click)="startFilter('fullName')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['fullName']" [(ngModel)]="searchTerm['fullName']" class="search"
                        placeholder="Chercher un email">


                      <span *ngIf="this.viewSearch['fullName']" (click)="endFilter('fullName')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'fullName', type: 'string', searchTerm: searchTerm['fullName']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='fullName' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='fullName' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'fullName', type: 'string', searchTerm: searchTerm['fullName']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='fullName' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='fullName' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['key']"> Clé </span>
                      <span *ngIf="!this.viewSearch['key']" (click)="startFilter('key')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['key']" [(ngModel)]="searchTerm['key']" class="search"
                        placeholder="Chercher une clé">


                      <span *ngIf="this.viewSearch['key']" (click)="endFilter('key')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'key', type: 'string', searchTerm: searchTerm['key']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='key' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='key' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'key', type: 'string', searchTerm: searchTerm['key']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='key' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='key' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['date']"> Date </span>
                      <span *ngIf="!this.viewSearch['date']" (click)="startFilter('date')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['date']" [(ngModel)]="searchTerm['date']" class="search"
                        placeholder="Chercher une clé">


                      <span *ngIf="this.viewSearch['date']" (click)="endFilter('date')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'date', type: 'string', searchTerm: searchTerm['date']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='date' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='date' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'date', type: 'string', searchTerm: searchTerm['date']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='date' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='date' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['duration']"> Durée </span>
                      <span *ngIf="!this.viewSearch['duration']" (click)="startFilter('duration')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['duration']" [(ngModel)]="searchTerm['duration']" class="search"
                        placeholder="Chercher une durée">


                      <span *ngIf="this.viewSearch['duration']" (click)="endFilter('duration')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'duration', type: 'string', searchTerm: searchTerm['duration']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='duration' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='duration' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'duration', type: 'string', searchTerm: searchTerm['duration']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='duration' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='duration' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['goals']"> Objectif </span>
                      <span *ngIf="!this.viewSearch['goals']" (click)="startFilter('goals')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['goals']" [(ngModel)]="searchTerm['goals']" class="search"
                        placeholder="Chercher un objrectif">


                      <span *ngIf="this.viewSearch['goals']" (click)="endFilter('goals')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'goals', type: 'string', searchTerm: searchTerm['goals']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='goals' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='goals' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'goals', type: 'string', searchTerm: searchTerm['goals']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='goals' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='goals' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['decisions']"> Décision </span>
                      <span *ngIf="!this.viewSearch['decisions']" (click)="startFilter('decisions')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['decisions']" [(ngModel)]="searchTerm['decisions']" class="search"
                        placeholder="Chercher une décision">


                      <span *ngIf="this.viewSearch['decisions']" (click)="endFilter('decisions')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'decisions', type: 'string', searchTerm: searchTerm['decisions']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='decisions' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='decisions' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'decisions', type: 'string', searchTerm: searchTerm['decisions']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='decisions' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='decisions' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>
                    
                    <!--/th>
                    <th class="center">Duration
                      <span (click)="setSort('duration')">
                        <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='duration' && this.order =='normal')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="this.sort=='duration' && this.order =='normal'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span (click)="setInvertSort('duration')">
                        <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='duration' && this.order =='invert')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="this.sort=='duration' && this.order =='invert'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>
                    </th>
                    <th class="center">Objectifs
                      <span (click)="setSort('goals')">
                        <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='goals' && this.order =='normal')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="this.sort=='goals' && this.order =='normal'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span (click)="setInvertSort('goals')">
                        <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='goals' && this.order =='invert')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="this.sort=='goals' && this.order =='invert'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center"> Décisions
                      <br>
                      <span (click)="setSort('decisions')">
                        <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='decisions' && this.order =='normal')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="this.sort=='decisions' && this.order =='normal'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span (click)="setInvertSort('decisions')">
                        <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='decisions' && this.order =='invert')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="this.sort=='decisions' && this.order =='invert'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>
                    </th>
                    <th class="center">Comptabilisable

                      <span (click)="setSort('isActivable')">
                        <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='isActivable' && this.order =='normal')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="this.sort=='isActivable' && this.order =='normal'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span (click)="setInvertSort('isActivable')">
                        <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='isActivable' && this.order =='invert')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="this.sort=='isActivable' && this.order =='invert'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>
                    </th-->
                    <!--<th class="center">Collègues </th>
                    <th class="center">Collègue </th>-->
                    <th class="center">Comptabilisable </th>
                    <th class="center">Validé </th>
                    <th class="center">Activé </th>
                    <th class="center">Total </th>
                  </tr>

                  <tbody *ngIf="hideWhenNoTravCol">
                    <!-- *ngFor loop iterates over travcol array and fetch the travcol's data -->
                    <!-- paginate pipe will add pagination in travcol's list, it won't show if items are less then 7 -->
                    <tr
                      *ngFor="let travcol of allTravcols 
                       |filter:column:searchTerm[column]
                       |sort:mode:column:type
                       | paginate: { itemsPerPage: 8, currentPage: p };  let i=index">

                      <td class="center" style="font-weight: bold;">{{travcol.userEmailID}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.fullName}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.initials}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.key}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.date|date:'dd/MM/yyyy'}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.duration}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.goals}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.decisions}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.isActivable}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.validated}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.activated}} </td>
                      <td class="center" style="font-weight: bold;">{{travcol.totalPeriods|number: '1.0-1' }} </td>
                    </tr>
                  </tbody>
                </table>
                <a>
                  <pagination-controls class="my-pagination" (pageChange)="p = $event" autoHide="true" responsive="true"
                    previousLabel="Précédent" nextLabel="Suivant">
                  </pagination-controls>
                </a>
              </div>
              <!--<div> <button  (click)="downloadAsPDF()">Export To PDF</button></div>   -->
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>