import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  parameterRef: AngularFireObject<any>
  constructor(

    private db: AngularFireDatabase,
  ) {//console.log(Date() ,'constructor')
  }



  // Fetch Single Student Object
  GetParameter() {
    this.parameterRef = this.db.object('globalParameters/');
    return this.parameterRef;
  }

  GetInfoPage() {
    this.parameterRef = this.db.object('globalParameters/infoPage');
    return this.parameterRef;
  }

  GetSuffix() {
    this.parameterRef = this.db.object('globalParameters/emailSuffix');
    return this.parameterRef;
  }

  updateInfoPage(infoPage) {
    this.db.object('globalParameters/').update({
      infoPage: infoPage,
    })
      .catch(function (error) {
        console.log(Date(), "updateInfoPage failed: " + error.message)
      });
  }

  updateParameters(params) {
 
    this.db.object('globalParameters/').update(params)
      .catch(function (error) {
        console.log(Date(), "updateParameters failed: " + error.message)
      });
  }
}

