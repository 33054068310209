import { Component, OnInit } from '@angular/core';
import { TravcolsService } from 'src/app/components/Travcols/travcols.service';  // CRUD API service class
import { ToastrService } from 'ngx-toastr';      // Alert message using NGX toastr
import { TravCol } from 'src/app/components/Travcols/travcol';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/components/boilerplate/auth.service';
import { ColleagueOfTravcol } from '../colleague-of-travcol';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ParametersService } from '../../others/parameters.service';

@Component({
  selector: 'app-travcols-to-be-validated',
  templateUrl: './travcols-to-be-validated.component.html',
  styleUrls: ['./travcols-to-be-validated.component.css']
})
export class TravcolsToBeValidatedComponent implements OnInit {
  p: number = 1;                      // Settup up pagination variable
  public travcols: TravCol[];                 // Save travcols data in travcol's array.
  public colleaguesOfTravcol: ColleagueOfTravcol[];
  hideWhenNoTravCol: boolean = false; // Hide travcols data table when no travcol.
  noData: boolean = false;            // Showing No travcol Message, when no travcol in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)
  closeModal: string;
  isOwnerOfTC: boolean = false;
 
  public emailID: string;
    //public colleaguesList: string
  public userQuotaInMinutes
  toBeDisplayed: boolean = false;
  localUserData: LocalUser;;
  parameters: Object;
  public viewSearch = {
    "date": false,
    "duration": false,
    "colleaguesListString": false,
    "typeOfTC": false,
    "goals": false,
    "decisions": false,
    "initials": false,
  }
// config tri par défaut
mode: string = "asc";
column: string = "date";
type: string = "string";
searchTerm={};

  constructor(

    private modalService: NgbModal,
    public travcolsApi: TravcolsService, // Inject travcol CRUD services in constructor.
    public toastr: ToastrService, // Toastr service for alert message
    public router: Router,
    public authservice: AuthService,
    public parametersApi: ParametersService,

  ) { /*console.log(Date() ,'constructor')*/
  }


  ngOnInit() {
    //console.log(Date() ,'ngoninit')
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)


      this.userQuotaInMinutes = this.localUserData.quota * 60
      this.emailID = this.localUserData.emailID
    }

    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      this.parameters = data.payload.toJSON()

    })


    //console.log(Date() ,this.userQuotaInMinutes)
    let s = this.travcolsApi.GetTravColsList(this.localUserData.emailID);
    s.valueChanges().subscribe(sData => {
      this.dataState(sData); // Initialize travcol's list, when component is ready
      //console.log(Date() ,sData)
      let travcols = {}
      sData.forEach(sElement => {
        travcols = { ...travcols, ...sElement }
      });
      //console.log(Date() ,travcols)
      //let a=[]
      this.travcols = [];
      Object.values(travcols).forEach(travcol => {
        this.colleaguesOfTravcol = [];
        Object.values(travcol['colleaguesOfTravcol']).forEach(colleaguesOfTravcol => {
          colleaguesOfTravcol['key'] = colleaguesOfTravcol['colleagueEmailID']
          this.colleaguesOfTravcol.push(colleaguesOfTravcol as ColleagueOfTravcol)
          //console.log(Date() ,colleaguesOfTravcol['colleagueEmailID'], ' ', travcol['userEmailID'], ' ',colleaguesOfTravcol['hasValidated'] )
          if (colleaguesOfTravcol['colleagueEmailID'] == travcol['userEmailID'] && colleaguesOfTravcol['hasValidated'] == false) {
            this.toBeDisplayed = true
          }
        });
        travcol["colleaguesOfTravcol2"] = this.colleaguesOfTravcol
        if (this.toBeDisplayed) {
          this.travcols.push(travcol as TravCol);
          this.toBeDisplayed = false
        }
      });
      this.dataState(this.travcols)
    });
  }

  // Using valueChanges() method to fetch simple list of travcols data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState(data) {
    this.preLoader = false;
    if (data.length <= 0) {
      this.hideWhenNoTravCol = false;
      this.noData = true;
    } else {
      this.hideWhenNoTravCol = true;
      this.noData = false;
    }

  }


  getTotal(travcol: TravCol[]) {

    let myObject = [];

    myObject['totalMinute'] = 0;
    myObject['totalPourcent'] = 0;
    myObject['totalPeriode'] = 0;

    if (travcol != null) {
      travcol.forEach(element => {


        if (/*element.isActivable*/true) {

          myObject['totalMinute'] += Number(element.duration);
        }
      });
    }

    myObject['totalPeriode'] = myObject['totalMinute'] / 50;
    myObject['totalPeriode'] = Number(myObject['totalPeriode'].toFixed(1));
    myObject['totalPourcent'] = Math.round(myObject['totalMinute'] / 30);




    return myObject;

  }



  //vérifie si le user current est ben le propriétaire du travcol
  getmatching(travcol) {
    //console.log(Date() ,travcol)
    if (travcol.ownerID != null) {
      if (travcol.ownerID == this.emailID) {
        return this.isOwnerOfTC = true;
      }
      else {
        return this.isOwnerOfTC = false;
      }
    }
  }



  deleteChoice(modal, travcol) {
    //console.log(Date() ,travcol);
    this.getmatching(travcol);
    this.triggerModal(modal, travcol);
  }

  triggerModal(content, travcol) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
        //console.log(Date() ,this.closeModal);
        this.modalWChoice(this.closeModal, travcol);
      }, (res) => {

        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
        //console.log(Date() ,this.closeModal );
        this.modalWChoice(this.closeModal, travcol);
      })
      .catch(function (error) {
        console.log(Date(), "triggerModal failed: " + error.message)
      });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      //console.log(Date() ,'by pressing ESC')
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      //console.log(Date() ,'by clicking on a backdrop')
      return 'by clicking on a backdrop';
    } else {
      //console.log(Date() ,`with: ${reason}`)
      return `with: ${reason}`;
    }
  }

  modalWChoice(choice: string, travcol) {
    switch (choice) {
      case "Closed with: Modifier": {
        //console.log(Date() ,"Modifier")
        let path = "/edit-travcol/" + travcol.userEmailID + "/" + travcol.ownerID + "/" + travcol.key
        //console.log(Date() ,path)
        this.router.navigate([path]);

        break;
      }
      case "Closed with: Effacer": {

        if (this.isOwnerOfTC) {
          //extermination du travcol

          Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
            //console.log(Date() ,colleaguesOfTravcolKey)
            this.travcolsApi.DeleteTravColForOneColleague(colleaguesOfTravcolKey, travcol);
          })

          this.toastr.success("Voilà, c'est malin, maintenant vous avez tout effacé! Tant pis si les autres vous rouspètent !"); // Alert message will show up when travcol successfully deleted.
          break;

        }
        else {
          //se retirer du travcol
          //console.log(Date() ,travcol.key)
          this.removeFromTravcol(travcol)


          break;
        }

      }
      default: {
        //statements; 
        break;
      }
    }
  }


  removeFromTravcol(travcol) {

    //Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
    //console.log(Date() ,colleaguesOfTravcolKey)
    //console.log(Date() ,data)
    //if (data != null) {
    if (Object.keys(travcol.colleaguesOfTravcol).length == 2) {

      Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
        //console.log(Date() ,colleaguesOfTravcolKey)
        this.travcolsApi.DeleteTravColForOneColleague(colleaguesOfTravcolKey, travcol);
      })
      this.toastr.success("Le travail collaboratif a été complètement effacé car vous n'étiez que deux collègues"); // Alert message will show up when travcol successfully deleted.
    }
    else {
      this.travcolsApi.DeleteTravCol(travcol)
      delete travcol.colleaguesOfTravcol[travcol.userEmailID]
      //console.log(Date() ,travcol)
      Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
        //console.log(Date() ,colleaguesOfTravcolKey)
        travcol.userEmailID = colleaguesOfTravcolKey
        //delete travcol.colleaguesList
        this.travcolsApi.UpdateTravcol(travcol) //écrire colleague dans travcol 


      })
      
      this.toastr.success("Vous avez bien été retiré du TC, il n'est maintenant plus dans votre liste. ");

    }
  }


  getMatchingTable(travcol) {

    if (travcol != null) {
      if (this.emailID == travcol.ownerID) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  putHasValidated(travcol) {
    travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] = true
    let cpt = 0
    Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
      //delete travcol.colleaguesList
      travcol.userEmailID = colleaguesOfTravcolKey

      if (travcol.colleaguesOfTravcol[colleaguesOfTravcolKey]['hasValidated']) {
        cpt++
        //console.log(Date() ,cpt)
      }
      if (cpt > 1) {
        travcol['isActivable'] = true
      }
      else {
        travcol['isActivable'] = false
      }


    });

    Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {

      this.travcolsApi.UpdateTravcolForOneUser(colleaguesOfTravcolKey, travcol)


/* µ
récupérer la totalperiod, mttre ajour a prtir de duration , et sauver

myObject['totalPeriode'] = myObject['totalMinute'] / 50;
    myObject['totalPeriode'] = Number(myObject['totalPeriode'].toFixed(1));
    if (this.totalPeriods != myObject['totalPeriode']) {
      this.colleaguesApi.UpdateTotalPeriods(this.emailID, myObject['totalPeriode'].toFixed(0))
      this.totalPeriods = myObject['totalPeriode']*/


    })


  }
  

  setSortParams(param) {
    let vsKeys = Object.keys(this.viewSearch)
    vsKeys.forEach(element => {
      //console.log(element)
      if (element != param.column) {
        this.viewSearch[element] = false
      }

    });
    //console.log(param)
    this.mode = param.mode;
    this.column = param.column;
    this.type = param.type;
    //this.searchTerm = thi
  }

  startFilter(column) {
    let vsKeys = Object.keys(this.viewSearch)
    vsKeys.forEach(element => {
      //console.log(element)
      this.viewSearch[element] = false
    });
    //console.log(column)
    this.mode = ""
    this.column = column;
   
    this.viewSearch[column] = true

  }

  endFilter(column) {
    this.searchTerm[column] = ""
    this.viewSearch[column] = false

  }


}