

    <header class="page-header" id="header" >
      <app-header></app-header>
    </header>
    
    <div class=" border-bottom" style="text-align: center; margin-bottom: -30px; margin-top: 100px;">
  <h1 style="color:white;">Profil de l'utilisateur</h1>
</div>

<div class="container mt-5 text-center">

</div>

<div class="wrapper">
  
  <div class="cards_wrap">
    <div *ngIf="this.localUserData" class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div *ngIf="!localUserData.isInvite" style="text-align: right;margin-bottom: -25px">
          <button aria-label="." ngbTooltip="Aide" class="btn" (click)="triggerModal(modalData)">
            <span class="icon-iconInfo" style="font-size: 1.4em;"></span>
          </button>
        </div>

        <!--PARTIE INVITE -->
        <div class="card_top center">
          <label *ngIf="this.mode=='Defaut'&&localUserData &&localUserData.isInvite" class="hoverable" style="margin:auto;cursor: auto;">
            <img aria-label="." aria-label="."class="img3" style="object-fit: cover;" *ngIf="localUserData.photoURL=='Einstein'"
              src='./assets/images/profile2.png'>
            
          </label>
          </div>

          <!--PARTIE AUTRE-->
        <div class="card_top center" *ngIf="localUserData &&!localUserData.isInvite"[ngStyle]="{'margin-bottom':this.mode=='Defaut' ? '-90px' : '0px' }">



          <label *ngIf="this.mode=='Defaut'" class="hoverable" style="margin:auto;" for="fileInput">
            <img aria-label="."class="img3" style="object-fit: cover;" *ngIf="localUserData.photoURL!='Einstein'"
              src={{localUserData.photoURL}}>
            <img aria-label="."class="img3" style="object-fit: cover;" *ngIf="localUserData.photoURL=='Einstein'"
              src='./assets/images/profile2.png'>
            <div class="hover-text">Choisir une image</div>
            <div class="background">
            </div>
          </label>

          <input id="fileInput" type='file' accept="image/*" (change)="onFileChange($event)">


          <div *ngIf="this.mode=='Defaut'" id="conteneur_slider">
            <button aria-label="." class="btn" style="margin-top:-195px;margin-left:-200px;">
              <span ngbTooltip="Tronche" class="icon-iconTronches" style="font-size: 1.4em;" (click)="launchWebcam()"><span
                  class="path1"></span><span class="path2"></span></span>
            </button>
          </div>


          <div *ngIf="this.mode=='Defaut'" id="conteneur_slider">
            <button aria-label="." class="btn" style="margin-bottom:-150px;margin-right:-25px;margin-left: 180px;">
              <span ngbTooltip="Supprimer" class="icon-iconSupprimer" style="font-size: 1.4em;" (click)="deletePicture()"><span
                  class="path1"></span><span class="path2"></span></span>
            </button>
          </div>


          <webcam *ngIf="this.mode=='Webcam'" [height]="500" [width]="500" [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)" [videoOptions]="videoOptions" (initError)="handleInitError($event)">
          </webcam>
          <br />
          <button *ngIf="this.mode=='Webcam'" class="btn btn-primary" style="margin-right: 20px;" (click)="triggerSnapshot();">Waw! Je
            copie!
            <span class="icon-iconValider"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>

          </button>
          <button *ngIf="this.mode=='Webcam'" class="btn btn-primary" (click)="cancelSnapshot()">Aie! Vite!
            Coiffeur!
            <i class="icon-iconRetour" style="font-size: 1.3em;"></i>

          </button>




          <image-cropper *ngIf="this.mode=='Fichier'" [imageChangedEvent]="imgChangeEvt" [maintainAspectRatio]="true"
            [aspectRatio]="4 / 4" [resizeToWidth]="256" format="png" (imageCropped)="cropImg($event)"
            (imageLoaded)="imgLoad()" (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()"
            [roundCropper]="true">
          </image-cropper>


          <image-cropper *ngIf="this.mode=='CropWebcam'" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png"
            [imageBase64]="webcamImage.imageAsDataUrl" (imageCropped)="cropImg($event)" [roundCropper]="true">
          </image-cropper>

          <button *ngIf="(this.mode=='Fichier'|| this.mode=='CropWebcam') " class="btn btn-primary" style="margin-right: 15px;"
            (click)="submit()">Valider
            <span class="icon-iconValider"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>

          </button>

          <button *ngIf="(this.mode=='Fichier'|| this.mode=='CropWebcam')" type="button" class="btn btn-primary" (click)="cancelSnapshot()">
            Retour
            <span class="icon-iconRetour" style="font-size: 1.3em;"></span>
         </button>

        </div>
        <div class="card_bottom">
          <div class="card_info">
            <strong style="color:var(--primaryNew);">{{localUserData.displayName}}</strong>
            , bienvenue dans <span><img aria-label="." src="./assets/images/LogoBosscool.png" width="90px" height="30px" /></span> . <br>
            <ul style="padding-left: 35px;list-style-type:square">
              <li>votre adresse email est <strong
                  style="color:var(--primaryNew); text-align: justify;font-size: 14pt;">{{localUserData.email}}</strong>,</li>
              <li>vos initiales sont<strong style="color:var(--primaryNew);"> {{localUserData.initials}}</strong>,</li>
              
              <li>votre rôle est<strong style="color:var(--primaryNew);"> {{userRole}}</strong>,</li>
              <li *ngIf="localUserData &&!localUserData.isInvite">votre quota est <strong style="color:var(--primaryNew);">{{localUserData.quota}}</strong>.
                
              </li>
            </ul>
            <div *ngIf="localUserData &&!localUserData.isInvite" class=" d-flex justify-content-center  align-items-center">

            <button type="button" class="btn btn-primary" style="margin-right: 19px" (click)="triggerModal(modalDataQuota)">
              Modif quota
              <span class="icon-iconEdit" style="font-size: 1.3em;"><span class="path1"></span><span
              class="path2"></span></span>           
             </button>
             
             <button type="button" class="btn btn-primary" routerLink="/tronches/{{localUserData.emailID}}">
              Auto-descriptif
              <span class="icon-iconInscription" style="font-size: 1.3em;"><span
                class="path1"></span><span class="path2"></span></span>         
             </button>
            </div>
          </div>

          <br>Veuillez utiliser le menu en haut de cette page ou l'un des boutons suivants :<br>
          <div class="card_creator">
            <div class="d-flex justify-content-center align-items-center">
            <button *ngIf="localUserData &&!localUserData.isInvite" type="button" class="btn btn-primary" style="margin-right: 10px;padding-right: 3px;padding-left: 3px;" routerLink="/add-travcol">
              Ajouter un TC
              <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span
                  class="path2"></span></span>
            </button>
            <button type="button" class="btn btn-primary" style="margin-right: 10px;padding-right: 3px;padding-left: 3px;" routerLink="/travcols-to-be-validated">
              TC en attente
              <span class="icon-iconTCAValider"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>

            </button>
            <button type="button" class="btn btn-primary" style="margin-right: 0px;padding-right: 3px;padding-left: 3px;" routerLink="/travcols-list">
              TC accepté(s)
              <span class="icon-iconTCValides"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>

            </button>

          </div>

            

          </div>
        </div>
      </div>
    </div>



  </div>
</div>


<ng-template #modalData let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Pas de panique !</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;">

    <ul style="padding-left: 35px;margin-bottom: -20px;">
      <li>Afin de sélectionner une photo sur votre appareil (ordinateur, smartphone, ...), cliquez sur Einstein (sans lui faire trop mal). Une fois l'image choisie,
        vous pouvez la rogner à votre guise.

      </li>
      <li>L'appareil photo <span class="icon-iconTronches" style="font-size: 1.4em;" ><span
        class="path1"></span><span class="path2"></span></span> vous permet de prendre directement une photo de vous, depuis la WebCam de votre appareil. 
      Allez, 3... 2... 1... Souriez ! </li>
      <li>Si vous ne vous trouvez pas à votre avantage sur votre photo, alors cliquez sur la poubelle
        <span class="icon-iconSupprimer" style="font-size: 1.4em;"><span
          class="path1"></span><span class="path2"></span></span>
        sans plus attendre ! Et voilà le retour d'Einstein. </li>
        <li>Vous pouvez aussi changer votre quota personnel en cliquant sur le crayon  
          <span class="icon-iconEdit"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
          et ajouter votre auto-descriptif en cliquant sur le bouton <span class="icon-iconInscription" style="font-size: 1.3em;"><span
            class="path1"></span><span class="path2"></span></span>   
        </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Fermer
      <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
      class="path2"></span></span>
    </button>
  </div>
</ng-template>


<ng-template #modalDataQuota let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Modification de votre quota</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;">

    <form [formGroup]="quotaForm"  novalidate> 
              <label>Quota</label>
              <input aria-label="." autofocus type="text" formControlName="quota" class="formControl left" >
    </form>
    
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="modal.close('Ok') ; updateQuota()" style="margin-right: 25px;" [disabled]="!quotaForm.valid">Modifier votre quota
      <span class="icon-iconEdit"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
  
    </button>
    <button type="button" class="btn btn-danger" (click)="modal.close()">Annuler
      <i class="icon-iconRetour" style="font-size: 1.3em;"></i>
    </button>
  </div>
</ng-template>