<header class="page-header" id="header">
  <app-header></app-header>
</header>
<div class=" border-bottom" style="text-align: center; margin-top: 100px; margin-bottom: 15px;">
  <h1 style="color:white;">Modification d'un collègue</h1>
</div>








<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div style="text-align: right;margin-bottom: -25px">
          <button aria-label="Aide" ngbTooltip="Aide" class="btn" (click)="triggerModal(modalDataEditColleagues)">
            <span class="icon-iconInfo" style="font-size: 1.4em;"></span>
          </button>
        </div>
        <div class="card_top center">

        </div>
        <div class="card_bottom">
          <form [formGroup]="ColleagueForm" (ngSubmit)="updateForm()" novalidate>

            <div class="card_info">

              <label>Prénom</label>
              <input aria-label="firstName" type="text" formControlName="firstName" style="text-transform:capitalize;"
                class="formControl left">
              <p *ngIf="firstName.touched && firstName.invalid" class="error"><sup>*</sup>Veuillez entrer un prénom</p>

              <label>Nom</label>
              <input aria-label="lastName" type="text" formControlName="lastName" style="text-transform: uppercase;" class="formControl left">
              <p *ngIf="firstName.touched && firstName.invalid" class="error"><sup>*</sup>Veuillez entrer un nom</p>
              <!--label>Email</label>
              <input type="text" formControlName="emailAddress" class="formControl left"-->
              <label>Rôle</label>
              <a *ngIf="enableRoleField">
                <ng-multiselect-dropdown class="formControl left custom-font-size" required
                  [placeholder]="'Choisissez un rôle'" [settings]="dropdownSettings" [data]="realRoleList"
                  formControlName="role" searchPlaceholderText='Entrez un rôle' [(ngModel)]="selectedItems"
                  (onSelect)="onItemSelect($event)">
                </ng-multiselect-dropdown>
              </a>
              <a *ngIf="!enableRoleField" style="color: white;font-size: 13pt;">
                <br>
                Pour pouvoir donner un rôle à cet utilisateur, veuillez d'abord le débloquer. 
              </a>
              <br>
                <a *ngIf="!enableRoleField">
                <button type="button" class="btn btn-primary" style="margin-right:25px;" (click)=" onNotBlock()">
                  Débloquer
                </button>
                </a>

                <a *ngIf="enableRoleField">
                <button type="button" class="btn btn-danger" style="margin-right:25px;" (click)=" onBlock()">
                  Bloquer la photo
                </button>
                </a>
              
              <div *ngIf="enabledQuota">
                <label>Quota</label>
                <input aria-label="Quota" type="text" formControlName="quota" class="formControl left">
                <p *ngIf="quota.touched && quota.invalid" class="error"><sup>*</sup>Veuillez entrer un quota</p>
                <p *ngIf="quota.errors?.pattern" class="error"><sup>*</sup>Utilisez uniquement des chiffres</p>
              </div>
            </div>
            <div class="card_creator">
              <button type="button" class="btn btn-danger" style="margin-right:25px;" (click)=" onBack()">
                Annuler
                <i class="icon-iconRetour" style="font-size: 1.3em;"></i>
              </button>

              <button type="submit" class="btn btn-primary" style="margin-right: 25px;"
                [disabled]="!ColleagueForm.valid">Modifier ce collègue
                <span class="icon-iconEdit" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>

              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #modalDataEditColleagues let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Besoin d'aide? Pas de panique !</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;">

    TEXTE A FAIRE
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Fermer</button>
  </div>
</ng-template>