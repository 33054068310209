import { Directive, Output, EventEmitter, ElementRef, NgZone, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';


@Directive({
  selector: '[appRecaptcha]'
})
export class RecaptchaDirective {

  @Output() recaptchaSuccess = new EventEmitter<string>();
  @Output() recaptchaExpired = new EventEmitter<unknown>();
  @Output() recaptchaError = new EventEmitter<unknown>();

  private readonly scriptId = 'volt-recaptcha';
  widgetId: number;
  captchaOK: boolean =false

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private readonly dom: Document,
  ) {  //console.log(Date() ,'constructor')
  }


  ngOnInit() {
    //console.log(Date() ,'ngoninit')
 
      this.registerCaptchaCallback();
      this.addScript();  
   
      (async () => { 
        // Do something before delay
        //console.log('before delay')

        await this.delay(2000);

        // Do something after
        if (!this.captchaOK){
        //console.log('after delay')
        window.location.reload()
        }
    })();
  
  }


delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}


  ngOnDestroy() {
    this.widgetId = null;
  }

  private registerCaptchaCallback() {
    (window as any).recaptchaCallback = () => {
      const config = {
       // https://bosscool-6886c.web.app  sitekey: '6LeRndwaAAAAAOBVtNz4ErOoh-JU52yOtHhr5Wcv',
       //https://boscooldev.web.app    sitekey: '6LdrhZEcAAAAAOVyh1Ez5EOdX3pSEdfRXujEIafX',
       // *.firebaseapp.com et *.web.app et localhost et bosscool.be
        sitekey: '6LcHi5EcAAAAAHtN06I0nwBAcAOSqPsBZ_5JY5kS',
        callback: this.onSuccessCallback.bind(this),
        'error-callback': this.onErrorCallback.bind(this),
        'expired-callback': this.onExpiredCallback.bind(this),
        //theme : 'custom',
        //size : 'compact'

      };
      this.widgetId = this.renderCaptcha(config);
    };
  }

  private addScript() {
    if (this.dom.getElementById(this.scriptId) != null) {
      return;
    }

    const script = this.dom.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit&hl=fr';
    script.id = this.scriptId;
    script.async = true;
    script.defer = true;
    this.dom.body.appendChild(script);
  }

  private onSuccessCallback(token: string) {
    //console.log(Date() ,'')
    this.ngZone.run(() => {
      this.recaptchaSuccess.emit(token);
    });
  }

  private onErrorCallback() {
    //console.log(Date() ,'')
    this.ngZone.run(() => {
      this.recaptchaError.emit();
    });
  }

  private onExpiredCallback() {
    //console.log(Date() ,'')
    this.ngZone.run(() => {
      this.recaptchaExpired.emit();
    });
  }

  private renderCaptcha(config: any): number {
    //console.log(Date() ,'')
    this.captchaOK=true
    return (window as any).grecaptcha.render(this.elementRef.nativeElement, config);
  }

 

}