import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object
import { TravCol } from './travcol';  // TravCol data type interface class
import { AuthService } from '../boilerplate/auth.service';
import { LocalUser } from '../boilerplate/local-user';

@Injectable({
  providedIn: 'root'

})
export class TravcolsService {
  todayDate: Date = new Date();
  travcolsRef: AngularFireList<any>;    // Reference to TravCol data list, its an Observable
  travcolRef: AngularFireObject<any>;   // Reference to TravCol object, its an Observable too
  addTravColPushedKey: string;
  GetColleaguesFromOneTravColRef: AngularFireList<any>;
  GetObjectColleaguesFromOneTravColRef: AngularFireObject<any>;
  debug: boolean = false
  localUserData: LocalUser;;
  date = new Date();


  constructor(

    public authservice: AuthService,
    private db: AngularFireDatabase,
  ) { //console.log(Date() ,'constructor')
    /* if (localStorage.getItem('userData') !== null) {
       this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
       //console.log(Date() ,this.localUserData)
 
     }
     this.emailID = this.localUserData.emailID*/
    //console.log(Date() ,'islogged:',localUserData.isLogged)

  }



  timeStamp = this.date.toLocaleString('fr-BE', {
    timeZone: 'Europe/Brussels',
  })




  CreateTravcolKeyRef(travcol) { //laisser en gris
    return this.travcolsRef.push({}).key
  }


  AddTravColWithCustomKey(customKey: string, travcol: TravCol) {
    //console.log(Date() ,travcol)

    var path = 'travcols-' + this.revSchoolYear() + '/' + travcol.colleagueEmailID + '/' + travcol.userEmailID + '/' + customKey
    if (this.debug) {
      //console.log(Date() ,'AddTravColWithCustomKey:', path)
    }
    const myTravColRef = this.db.database.ref().child(path);
    myTravColRef.set(travcol)
    myTravColRef.update({
      userEmailID: travcol.colleagueEmailID,
      modificationDate: this.timeStamp
    })


  }
  AddColleagueOfTravcol(travcol, colleagueOfTravcol) {
    var path = 'travcols-' + this.revSchoolYear() + '/' + travcol.colleagueEmailID + '/' + travcol.userEmailID + '/' + travcol.key + '/colleaguesOfTravcol/' + colleagueOfTravcol.colleagueEmailID + '/'
    if (this.debug) {
    }
    this.db.object(path).set(colleagueOfTravcol)
    this.db.object(path).update({ 
      userEmailID: travcol.colleagueEmailID,
      modificationDate: this.timeStamp
     })
      .then(function () {
        //console.log(Date() ,"Remove succeeded.")
      })
      .catch(function (error) {
        console.log(Date(), "Add ColleagueOfTravcol failed: " + error.message)
      });
  }



  // Delete TraCol Object
  DeleteTravColForOneColleague(colleaguesOfTravcolKey, travcol) {
    var path = 'travcols-' + this.revSchoolYear() + '/' + colleaguesOfTravcolKey + '/' + travcol.ownerID + '/' + travcol.key
    if (this.debug) {
      //console.log(Date() ,'DeleteTravColForOneColleague:', path)
    }
    else {
      const myTravColRef = this.db.database.ref().child(path);
      myTravColRef.remove()
        .then(function () {
          //console.log(Date() ,"Remove succeeded.")
        })
        .catch(function (error) {
          console.log(Date(), "DeleteTravColForOneColleague failed: " + error.message)
        });
    }
  }



  // Fetch Single Student Object
  GetTravCol(travcol) {
    //console.log(Date() ,travcol)
    var path = 'travcols-' + this.revSchoolYear() + '/' + travcol.userEmailID + "/" + travcol.ownerID + '/' + travcol.key
    if (this.debug) {
      //console.log(Date() ,'GetTravCol:', path)
    }

    this.travcolRef = this.db.object(path);
    //console.log(Date() ,this.travcolRef)
    return this.travcolRef;
  }





  UpdateTravcol(travcol) {

    var path = 'travcols-' + this.revSchoolYear() + '/' + travcol.userEmailID + "/" + travcol.ownerID + '/' + travcol.key
    if (this.debug) {
      //console.log(Date() ,'UpdateTravcol:', path)
    }
    else {
      this.db.object(path).update(travcol)

        .then(function () {
          //console.log(Date() ,"Remove succeeded.")
        })
        .catch(function (error) {
          console.log(Date(), "UpdateTravcol failed: " + error.message)
        });
      this.db.object(path).update({ modificationDate: this.timeStamp })
      .then(function () {
        //console.log(Date() ,"Remove succeeded.")
      })
      .catch(function (error) {
        console.log(Date(), "UpdateTravcolForOneUser part2 failed: " + error.message)
      });
    }
  }

  UpdateTravcolForOneUser(user, travcol) {

    var path = 'travcols-' + this.revSchoolYear() + '/' + user + "/" + travcol.ownerID + '/' + travcol.key
    if (this.debug) {
      //console.log(Date() ,'UpdateTravcolForOneUser:', path)
      //console.log(Date() ,travcol)
    }
    else {
      this.db.object(path).update(travcol)
        .then(function () {
          //console.log(Date() ,"Remove succeeded.")
        })
        .catch(function (error) {
          console.log(Date(), "UpdateTravcolForOneUser part1 failed: " + error.message)
        });
      this.db.object(path).update({ userEmailID: user })
        .then(function () {
          //console.log(Date() ,"Remove succeeded.")
        })
        .catch(function (error) {
          console.log(Date(), "UpdateTravcolForOneUser part2 failed: " + error.message)
        });
        this.db.object(path).update({ modificationDate: this.timeStamp })
        .then(function () {
          //console.log(Date() ,"Remove succeeded.")
        })
        .catch(function (error) {
          console.log(Date(), "UpdateTravcolForOneUser part2 failed: " + error.message)
        });
    }
  }


  // Fetch Single Student Object
  GetColleaguesFromOneTravCol(travcol: TravCol) {

    var path = 'travcols-' + this.revSchoolYear() + '/' + travcol.colleagueEmailID + '/' + travcol.ownerID + "/" + travcol.key + '/colleaguesOfTravcol/'
    if (this.debug) {
      //console.log(Date() ,travcol)
      //console.log(Date() ,'GetColleaguesFromOneTravCol:', path)
    }

    this.GetObjectColleaguesFromOneTravColRef = this.db.object(path);
    return this.GetObjectColleaguesFromOneTravColRef;

  }




  DeleteOneColleagueViewFromOneTravcol(colleaugueOfTravcolEmailID, travcol: TravCol) {
    var path = 'travcols-' + this.revSchoolYear() + '/' + colleaugueOfTravcolEmailID + '/' + travcol.ownerID + "/" + travcol.key + '/colleaguesOfTravcol/' + travcol.userEmailID
    if (this.debug) {
      //console.log(Date() ,'DeleteOneColleagueViewFromOneTravcol:', path)
    }
    else {

      this.travcolRef = this.db.object(path);
      this.travcolRef.remove()
        .then(function () {

          //console.log(Date() ,"Remove succeeded.")
        })
        .catch(function (error) {
          console.log(Date(), "DeleteOneColleagueViewFromOneTravcol failed: " + error.message)
        });

    }
  }


  // Delete TraCol Object
  DeleteTravCol(travcol) {
    var path = 'travcols-' + this.revSchoolYear() + '/' + travcol.userEmailID + "/" + travcol.ownerID + "/" + travcol.key
    if (this.debug) {
      //console.log(Date() ,'DeleteTravCol:', path)
    }
    else {

      this.db.object(path).remove()
        .then(function () {
          //console.log(Date() ,"Remove succeeded.")
        })
        .catch(function (error) {
          console.log(Date(), "DeleteTravCol failed: " + error.message)
        });
    }
  }


  // Fetch TravCols List
  GetTravColsList(emailID) {
    //console.log(emailID)
    this.travcolsRef = this.db.list('travcols-' + this.revSchoolYear() + '/' + emailID);
    return this.travcolsRef;
  }

  GetAllTravColsList() {
    this.travcolsRef = this.db.list('travcols-' + this.revSchoolYear() + '/');
    
    return this.travcolsRef;
  }

  GetTravcolsOfOneOwner(ownerID, emailID) {
    var path = 'travcols-' + this.revSchoolYear() + '/' + emailID + '/' + ownerID + '/'
    //console.log(Date() ,'GetTravcolsOfOneOwner:',path)
    this.travcolsRef = this.db.list(path);
    return this.travcolsRef
  }


  revSchoolYear() {


    let init = 0;
    let final = 0;
    let SchoolYear = "";
    let month = this.todayDate.getMonth() + 1;
    let limitDate = new Date (this.todayDate.getFullYear(),6,8)
    //console.log(limitDate, this.todayDate)
    if (this.todayDate > limitDate) {
      init = Number(this.todayDate.getFullYear());
      final = Number(this.todayDate.getFullYear()) + 1;
    }
    else {
      init = Number(this.todayDate.getFullYear()) - 1;
      final = Number(this.todayDate.getFullYear());
    }
    SchoolYear = final.toString() + init.toString();
    //console.log(Date() ,month)
    //console.log(Date() ,"year",SchoolYear);
    return SchoolYear
  }







}
