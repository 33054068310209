<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<div class=" border-bottom" style="text-align: center; margin-bottom: 15px; margin-top: 100px;">
  <h1 style="color:white;">Modification d'un Travail Collaboratif existant</h1>
</div>



<div *ngIf="parameters">
<form [formGroup]="editForm" (ngSubmit)="updateForm()" novalidate>
  <div class="wrapper">
    <div class="cards_wrap">
      <div class="card_item" style="margin-bottom: 20px;">
        <div class="card_inner">
          <div class="card_top center">

          </div>
          <div class="card_bottom" style="padding-bottom: 10px;padding-top: 2px;">
            <!--<div class="card_category">
                Inscription
              </div>-->
            <div class="card_info" style="padding-top: 0px;margin-top: 0px;">
              

                <!--<p class="title">10 Best Things about Travel</p>-->

                <label>Date</label>
                <input placeholder="."type="date" formControlName="date" min={{minDate}} max={{strTodayDate}} class="formControl left " required>
                <!-- Showing errors using getter method -->
                <p *ngIf="date.touched && date.invalid" class="error"><sup>*</sup>Veuillez entrer une date valide</p>
                <p *ngIf="date.errors?.minlength" class="error"><sup>*</sup>Voyons ! Une date fait plus de 2
                  caractères</p>
                  <p *ngIf="date.errors?.minMaxDateValidator" class="error"><sup>*</sup>Veuillez entrer une date pour cette année scolaire</p>
  

                <label>Durée (en minutes)</label>
                <input placeholder="."type="text" formControlName="duration" class="formControl left" required>
                <!-- Showing errors using getter method -->
                <p *ngIf="duration.touched && duration.invalid" class="error"><sup>*</sup>Veuillez entrer une durée en
                  minutes</p>
                <p *ngIf="duration.errors?.pattern" class="error"><sup>*</sup>Utilisez uniquement des chiffres</p>

                <label *ngIf="getmatching()">Collègues</label>
                <div>
                  <!--<input type="text" formControlName="colleague" class="formControl left">
            <p *ngIf="colleague.touched && colleague.invalid" class="error"><sup>*</sup>Veuillez entrer dans un collègue</p>
            <p *ngIf="colleague.errors?.minlength" class="error"><sup>*</sup>Veuillez entrer au moins 6 caractères</p>-->


                  <ng-multiselect-dropdown *ngIf="getmatching()" class="formControl left custom-font-size" required
                    [placeholder]="'Modifiez la liste de vos collègues'" [settings]="this.dropdownSettings"
                    [data]="favorites" formControlName="colleaguesOfTravcol" style="width:90%;display: inline-block;"
                    (onSelect)="onItemSelect($event)">
                  </ng-multiselect-dropdown>


                  <span ngbTooltip="Favoris" routerLink="/colleagues-list"
                    style="font-size: 2em;margin-left: 10px;vertical-align: middle;cursor:pointer"
                    class="icon-iconFavoris"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span></span>
                </div>


                <div *ngIf="parameters['TCType']">
                  <label>Type de TC</label>
                  <input placeholder="." type="text" formControlName="typeOfTC" class="formControl left">
                  <p *ngIf="typeOfTC.touched && typeOfTC.invalid" class="error"><sup>*</sup>Veuillez entrer un type de
                    travail collaboratif</p>
                </div>
                <label>Objectifs</label>
                <input placeholder="." type="text" formControlName="goals" class="formControl left">
                <p *ngIf="goals.touched && goals.invalid" class="error"><sup>*</sup>Veuillez entrer un objectif</p>

                <div *ngIf="parameters['TCDecisions']">
                  <label>Décisions</label>
                  <input placeholder="." type="text" formControlName="decisions" class="formControl left">
                  <p *ngIf="decisions.touched && decisions.invalid" class="error"><sup>*</sup>Veuillez entrer une
                    décision</p>
                </div>
              

              <!--input type="checkbox" formControlName="active" hidden  class="formControl left"-->

            </div>
            <div class="card_creator">

              <button type="button" class="btn btn-danger" style="margin-right:25px;" (click)=" onBack()">
                Annuler
                <i class="icon-iconRetour" style="font-size: 1.3em;"></i>
              </button>

              <button type="submit" class="btn btn-primary" style="margin-right: 25px;"
                [disabled]="!editForm.valid">Modifier ce TC
                <span class="icon-iconEdit" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>

              </button>

              <button *ngIf="getmatching()" type="button" (click)=" duplicate()" class="btn btn-primary"
                [disabled]="!editForm.valid">Nouveau TC à partir de celui-ci
                <span class="icon-IconDuplicate" style="font-size: 1.4em;"><span class="path1"></span><span
                    class="path2"></span></span>

              </button>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>