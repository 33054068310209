<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom"
  style="margin-top: 100px; margin-bottom: 15px;">
  <h1 style="color: white; margin-right: 25px;">Liste de tous les collègues </h1>

  <a  routerLink="/add-travcol" class="btn btn-secondary"
    style="border-radius:10px;padding: 10px;margin-top: 5px; margin-bottom: 15px; margin-right: 15px;" >
    Ajouter un TC
    <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
  </a>

  <!-- It won't show if there is no allcolleagues data -->
    <a *ngIf="hideWhenNoColleague&&localUserData.isAdmin" routerLink="/add-colleague" class="btn btn-secondary"
    style="border-radius:10px;padding: 10px;margin-top: 5px; margin-bottom: 15px;" >
    Ajouter un collègue
    <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
  </a>

  
</div>

<div class="pricing-header mx-auto">

  <!-- Preloader shows before the data loads-->
  <div class="no-data text-center" *ngIf="preLoader">
    <img src="assets/preloader.svg" class="preloader-icon" alt="">
  </div>

  <!-- No data shows when their is no allcolleagues data available -->
  <!--div class="no-data text-center" *ngIf="noData&&localUserData.isAdmin" >
    <img src="assets/no-student.svg" class="nodata-msg" alt="">
    <p style="color: white; ">Pas de collègue à afficher (Vous êtes seul au monde !)</p>
    <a  routerLink="/add-colleague" class="btn btn-secondary"
      style="border-radius:10px;padding: 10px;margin-top: 5px; margin-bottom: 15px;">

      Ajouter un collègue
      <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>
    </a>
  </div-->


  <div class="wrapper" style="min-width: 100%;">
    <div class="cards_wrap" style="min-width: 100%;">
      <div class="card_item" style="min-width: 100%;margin-bottom: 20px;">
        <div class="card_inner" style="min-width: 100%;">
          <div style="text-align: right;margin-bottom: -25px">
            <button aria-label="." ngbTooltip="Aide" class="btn" (click)="triggerModal(modalDataColleagues)">
              <span class="icon-iconInfo" style="font-size: 1.4em;"></span>
            </button>
            
          </div>
          <div class="card_top center" style="min-width: 100%;">
          </div>
          <div class="card_bottom" style="min-width: 100%;">
            <div class="card_info" style="min-width: 100%;">
              <!-- Showing allcolleagues data -->
              <div style="overflow-x:auto">

                <table scrollx="true" style="margin-left:auto;margin-right:auto;min-width: 100%;">

                  <tr>
                    
                    <th class="center">
                      <span *ngIf="!this.viewSearch['firstName']"> Prénom </span>
                      <span *ngIf="!this.viewSearch['firstName']" (click)="startFilter('firstName')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['firstName']" [(ngModel)]="searchTerm['firstName']" class="search"
                        placeholder="Chercher un prénom">


                      <span *ngIf="this.viewSearch['firstName']" (click)="endFilter('firstName')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'firstName', type: 'string', searchTerm: searchTerm['firstName']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='firstName' && mode =='asc')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='firstName' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'firstName', type: 'string', searchTerm: searchTerm['firstName']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='firstName' && mode =='dsc')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='firstName' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>



                    <th class="center">
                      <span *ngIf="!this.viewSearch['lastName']"> Nom </span>
                      <span *ngIf="!this.viewSearch['lastName']" (click)="startFilter('lastName')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['lastName']" [(ngModel)]="searchTerm['lastName']" class="search"
                        placeholder="Chercher un nom">


                      <span *ngIf="this.viewSearch['lastName']" (click)="endFilter('lastName')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'lastName', type: 'string', searchTerm: searchTerm['lastName']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='lastName' && mode =='asc')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='lastName' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'lastName', type: 'string', searchTerm: searchTerm['lastName']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='lastName' && mode =='dsc')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='lastName' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                      <th class="center">
                        <span *ngIf="!this.viewSearch['emailAddress']"> Email </span>
                        <span *ngIf="!this.viewSearch['emailAddress']" (click)="startFilter('emailAddress')">
                          <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                            style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                              class="path2"></span><span class="path3"></span></span>
                        </span>
  
                        <input *ngIf="this.viewSearch['emailAddress']" [(ngModel)]="searchTerm['emailAddress']" class="search"
                          placeholder="Chercher un email">
  
  
                        <span *ngIf="this.viewSearch['emailAddress']" (click)="endFilter('emailAddress')">
                          <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                            style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                              class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                        </span>
                        <span
                          (click)="setSortParams({mode: 'asc', column: 'emailAddress', type: 'string', searchTerm: searchTerm['emailAddress']})">
                          <span ngbTooltip="A -> Z" *ngIf="!(column=='emailAddress' && mode =='asc')"
                            class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                          <span ngbTooltip="A-> Z" *ngIf="column=='emailAddress' && mode =='asc'"
                            class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                              class="path1"></span><span class="path2"></span></span>
                        </span>
  
  
                        <span
                          (click)="setSortParams({mode: 'dsc', column: 'emailAddress', type: 'string', searchTerm: searchTerm['emailAddress']})">
                          <span ngbTooltip="Z -> A" *ngIf="!(column=='emailAddress' && mode =='dsc')"
                            class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                          <span ngbTooltip="Z -> A" *ngIf="column=='emailAddress' && mode =='dsc'"
                            class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                              class="path1"></span><span class="path2"></span></span>
                        </span>
  
                      </th>

                      <th class="center">
                        <span *ngIf="!this.viewSearch['realRole']"> Rôle </span>
                        <span *ngIf="!this.viewSearch['realRole']" (click)="startFilter('realRole')">
                          <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                            style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                              class="path2"></span><span class="path3"></span></span>
                        </span>
  
                        <input *ngIf="this.viewSearch['realRole']" [(ngModel)]="searchTerm['realRole']" class="search"
                          placeholder="Chercher un rôle">
  
  
                        <span *ngIf="this.viewSearch['realRole']" (click)="endFilter('realRole')">
                          <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                            style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                              class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                        </span>
                        <span
                          (click)="setSortParams({mode: 'asc', column: 'realRole', type: 'string', searchTerm: searchTerm['realRole']})">
                          <span ngbTooltip="A -> Z" *ngIf="!(column=='realRole' && mode =='asc')"
                            class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                          <span ngbTooltip="A-> Z" *ngIf="column=='realRole' && mode =='asc'"
                            class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                              class="path1"></span><span class="path2"></span></span>
                        </span>
  
  
                        <span
                          (click)="setSortParams({mode: 'dsc', column: 'realRole', type: 'string', searchTerm: searchTerm['realRole']})">
                          <span ngbTooltip="Z -> A" *ngIf="!(column=='realRole' && mode =='dsc')"
                            class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                          <span ngbTooltip="Z -> A" *ngIf="column=='realRole' && mode =='dsc'"
                            class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                              class="path1"></span><span class="path2"></span></span>
                        </span>
  
                      </th>

                    <!--th class="center" *ngIf="localUserData.isAdmin">
                      <span *ngIf="!this.viewSearch['realRole']"> Quota </span> 
                      

                      <span *ngIf="!this.viewSearch['realRole']" (click)="Search('realRole')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk" style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['realRole']" [(ngModel)]="searchTextRole" class="search"  placeholder="Chercher un rôle">


                      <span  *ngIf="this.viewSearch['realRole']" (click)="endFilter('realRole')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>

                    
                    </th-->

                    <th class="center">
                      <span *ngIf="!this.viewSearch['totalPeriods']"> Quota </span>
                      <span *ngIf="!this.viewSearch['totalPeriods']" (click)="startFilter('totalPeriods')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['totalPeriods']" [(ngModel)]="searchTerm['totalPeriods']" class="search"
                        placeholder="Chercher un quota">


                      <span *ngIf="this.viewSearch['totalPeriods']" (click)="endFilter('totalPeriods')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'totalPeriods', type: 'string', searchTerm: searchTerm['totalPeriods']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='totalPeriods' && mode =='asc')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='totalPeriods' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'totalPeriods', type: 'string', searchTerm: searchTerm['totalPeriods']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='totalPeriods' && mode =='dsc')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='totalPeriods' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>





                    <!--th *ngIf="localUserData.isAdmin" class="center">
                     
                      
                      <span ngbTooltip="Quota" *ngIf="!this.viewSearch['quota']" class="icon-iconQuota"style="font-size: 1.6em;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>

                     

                      <span *ngIf="!this.viewSearch['quota']" (click)="Search('quota')">
                        <!--span ngbTooltip="Filtrer" class="icon-iconFiltreOk" style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span-->
                      <!--/span>

                      <input *ngIf="this.viewSearch['quota']" [(ngModel)]="searchTextQuota" class="search" placeholder="Chercher un quota">


                      <span  *ngIf="this.viewSearch['quota']" (click)="endFilter('quota')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>

                    
                    </th-->

                    <th class="center" style="padding-top:15px;">
                      <span ngbTooltip="Changements" class="icon-iconInscription" style="font-size: 1.3em;padding-left: 50px;padding-right: 50px;"><span
                          class="path1"></span><span class="path2"></span></span>
                        </th>
                  </tr>

                  <tbody *ngIf="hideWhenNoColleague">
                    <!-- *ngFor loop iterates over TravCol array and fetch the allcolleagues's data -->
                    <!-- paginate pipe will add pagination in allcolleagues's list, it won't show if items are less then 7 -->
                    <tr [ngStyle]="{'color':colleague.isFavorite ? 'var(--favorites)' : 'white' }" *ngFor="let colleague of colleagues
                    |filter:column:searchTerm[column]
                    |sort:mode:column:type
                    |paginate: { itemsPerPage: 10, currentPage: p };
                        let i = index;">
                     
                      <td>{{colleague.firstName}}</td>
                      <td>{{colleague.lastName}}</td>
                      <td>{{colleague.emailAddress}}</td>
                      <td> {{colleague.realRole}}</td>
                      <td class="center" > {{colleague.totalPeriods|number: '1.0-1' }}/{{colleague.quota}}</td>
                     
                     
                      <td class="center">

                        <!--span class="icon-iconFavoris btn" style="font-size: 0.7em; padding-bottom: 17px; margin:none"
                          (click)="this.ToggleFavorite(colleague.key, colleague.firstName,colleague.lastName, colleague.isFavorite)">
                          <span class="icon-iconFavoris" style="font-size: 2.3em;"><span class="path1"></span><span
                              class="path2"></span><span class="path3"></span></span>
                      </span-->
                      <nobr>
                      <!--span class="icon-iconFavoris btn" style="font-size: 0.7em; padding-bottom: 17px; margin:none"
                      (click)="this.ToggleFavorite(colleague)">
                      <span ngbTooltip="Favori" class="icon-iconFavoris" style="font-size: 2.3em;margin-right: -15px;margin-left:-15px;" ><span class="path1"></span><span
                          class="path2"></span><span class="path3"></span></span>
                  </span-->

                  
                        
                        <span ngbTooltip="Modifier" *ngIf="localUserData.isAdmin && ((localUserData.role) >= (colleague.role))" class="icon-iconEdit" style="font-size: 1.5em;cursor: pointer;"
                           routerLink="/edit-colleague/{{colleague.colleagueEmailID}}"></span>
                        <span ngbTooltip="Supprimer" *ngIf="localUserData.isAdmin && ((localUserData.role) >= (colleague.role))" class="icon-iconSupprimer" style="font-size: 1.5em;cursor: pointer;"   (click)="this.DeleteColleague(colleague)"><span class="path1"></span><span
                            class="path2"></span></span>
                           <span ngbTooltip="Tronche" *ngIf="(localUserData.role)!='1,5'&&(localUserData.photoURL!='Einstein'&&colleague.photoURL&&colleague.photoURL!='Einstein')||(localUserData.isAdmin&&colleague.photoURL&&colleague.photoURL!='Einstein')" class="icon-iconTronches" routerLink="/tronches/{{colleague.colleagueEmailID}}" style="font-size: 1.4em;cursor: pointer;"><span
                              class="path1"></span><span class="path2"></span></span>
                              
                      </nobr>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_creator" style="margin-top: 10px;">
                <!-- Pagination -->
                <pagination-controls class="my-pagination" (pageChange)="p = $event" autoHide="true" responsive="true"
                  previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>


<ng-template #modalDataColleagues let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Pas de panique !</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;margin-bottom: -20px;">

    Ici, vous trouvez la liste de vos collègues.
   <br> 

   

    
    Vous pouvez soit :
    <ul style="padding-left: 35px;">
      <li>cliquer sur l'étoile <span class="icon-iconFavoris" style="font-size: 1.5em;" ><span class="path1"></span><span
        class="path2"></span><span class="path3"></span></span> pour ajouter à (ou retirer de) vos favoris le collègue correspondant.
        D'abord sa couleur se changera de blanc à <strong style="color: var(--favorites);">vert turquoise</strong> (ou inversément).
        Ensuite, il apparaîtra dans (ou disparaîtra de) la liste déroulante de la fenêtre d'ajout d'un TC.
      </li>
      <li>cliquer sur l'appareil photo  <span class="icon-iconTronches"style="font-size: 1.4em;"><span
        class="path1"></span><span class="path2"></span></span> pour afficher la tronche du collègue correspondant.
         Ceci ne sera possible que si vous avez vous-même choisi de montrer votre tronche dans votre profil. 

      </li>
      <span *ngIf="localUserData.isAdmin">
      <li>modifier <span class="icon-iconEdit" style="font-size: 1.5em;"></span>
        un collègue, </li>
      <li>supprimer <span class="icon-iconSupprimer" style="font-size: 1.4em;"><span class="path1"></span><span
            class="path2"></span></span> un collègue (aucun des travaux de ce collègue ne sera effacé mais il faut veiller
             à faire imprimer le carnet de cette personne avant de la retirer de la base de données),
      </li>
      <li>ajouter <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
        un collègue. Soyez attentif lors de l'encodage de son adresse email car elle sert de référence pour toutes 
      les opérations dans BossCool.</li>
    </span>
    </ul>
 

    <span *ngIf="localUserData.isAdmin">
    <span style="text-decoration: underline;"> Remarque</span> :

    <br> 
    Il n'est pas possible de modifier l'adresse email d'un collègue déjà enregistré. Si le besoin se présente,
     l'administrateur devra supprimer et recréer le collègue. Ainsi, ce dernier devra se réenregistrer dans BossCool.
  
    </span>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Fermer
      <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>
    </button>
  </div>

</ng-template>