
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Subscription } from 'rxjs';

import { filter } from 'rxjs/operators';


import { AuthService } from './components/boilerplate/auth.service';
import { UrlService } from './components/others/url.service';
export let browserRefresh = false;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'BossCoolV212';
  subscription: Subscription;

  previousUrl: string = null;
  currentUrl: string = null;
 

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
      if (document.hidden){
            //console.log("Page is hidden");
            //window.location.reload()
      } else {
            //console.log("Page is visible");
            window.location.reload()
      }
  }

  constructor(


    private router: Router,
    public authservice: AuthService,
    private urlService: UrlService
    
  ) {

    //console.log(Date() ,'AppComponent constructor ')

   

 
    
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;

      }

    });




  }


  ngOnInit() {
//console.log('init appcomponent')
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });
  


  }



  ngOnDestroy() {
 
    this.subscription.unsubscribe();



  }

}