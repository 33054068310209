import { Component, OnInit } from '@angular/core';
import { ColleaguesService } from '../colleagues.service';  // CRUD API service class
import { ToastrService } from 'ngx-toastr';      // Alert message using NGX toastr
import { Colleague } from 'src/app/components/colleagues/colleague';

import { AuthService } from 'src/app/components/boilerplate/auth.service';
import { ColleagueOfTravcol } from "src/app/components/Travcols/colleague-of-travcol";
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TravcolsService } from '../../Travcols/travcols.service';



//import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-admin-colleagues-list',
  templateUrl: './admin-colleagues-list.component.html',
  styleUrls: ['./admin-colleagues-list.component.css']
})
export class AdminColleaguesListComponent implements OnInit {

  realRole = { 1: "Invité", 2: "Utilisateur", 3: "Administrateur", 4: "Super-Zéro" };
  p: number = 1;                      // Settup up pagination variable
  public colleagues: Colleague[];                 // Save colleagues data in TravCol's array.
  hideWhenNoColleague: boolean = false; // Hide colleagues data table when no colleagues.
  noData: boolean = false;            // Showing No colleagues Message, when no colleagues in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)

  userEmailID: string
  colleagueOfTravcol: ColleagueOfTravcol;
  localUserData: LocalUser;
  closeModal: string;
  hideWhenNoTravCol: boolean = false;
  public allTravcols = [];                 // Save travcols data in travcol's array.
  public userEmailIdListAndTravcolIsActivable = [];
  totalPeriod: {}
  searchTerm={};
  mode:string="asc";
  column:string="firstName";
  type:string="string";
  public viewSearch = { 
    "firstName": false, "lastName": false, "emailAddress": false, "realRole": false, "totalPeriods": false }
  


  constructor(
    public colleaguesApi: ColleaguesService, // Inject colleagues CRUD services in constructor.
    public toastr: ToastrService, // Toastr service for alert message

    public authservice: AuthService,
    private modalService: NgbModal,
    public travcolsApi: TravcolsService,



  ) {
    //console.log(Date(), 'constructor')
  }

  ngOnInit() {
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      this.userEmailID = this.localUserData.emailID
    }


    let travcolList = this.travcolsApi.GetAllTravColsList();

    travcolList.valueChanges().subscribe(sData => {
      this.dataStateTC(sData); // Initialize travcol's list, when component is ready
      this.totalPeriod = {}
      sData.forEach(sElement => {
        Object.values(sElement).forEach(ownerID => {
          Object.values(ownerID).forEach(travcol => {
            if (travcol.colleaguesOfTravcol[travcol.userEmailID] != undefined) {
              travcol.validated = travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated']
              travcol.activated = travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']
            }
            travcol.userEmailID = travcol.userEmailID.replaceAll("*", ".")
            travcol.key = travcol.key.replaceAll("-", "")
            this.totalPeriod[travcol.userEmailID] = 0
            /*if (this.totalPeriod[travcol.userEmailID] == undefined) {
              this.totalPeriod[travcol.userEmailID] = 0
            }*/
            if (travcol.isActivable && travcol.validated && travcol.activated) {
              this.totalPeriod[travcol.userEmailID] += (Number(travcol.duration) / 50)
              travcol.totalPeriod = this.totalPeriod[travcol.userEmailID]
            }
            else {
              travcol.totalPeriod = this.totalPeriod[travcol.userEmailID]
            }
            //this.colleaguesApi.UpdateCurrentColleagueTotalPeriods(travcol.ownerID, travcol.totalPeriod);

          });
        });
      });

      //console.log(this.totalPeriod)



      this.dataState(); // Initialize colleagues's list, when component is ready



      let collList = this.colleaguesApi.GetColleaguesList();
      // collList.valueChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
      //    this.colleagues = [];

      collList.snapshotChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
        this.colleagues = [];

        data.forEach(item => {
          let colleague = item.payload.toJSON();
          //console.log(Date() ,a)
          colleague['colleagueEmailID'] = item.key;
          colleague['userEmailID'] = this.userEmailID
          //item.payload.hasChild('isFavoriteOf/' + this.userEmailID) ? colleague['isFavorite'] = true : colleague['isFavorite'] = false
          //colleague['realRole'] = this.realRole[colleague['role']]
          //this.colleagues.push(colleague as Colleague);

          // })
          //  })




          // data.forEach(colleague => {


          //let colleague = item.payload.toJSON();
          //colleague['userEmailID'] = this.userEmailID
          //item.payload.hasChild('isFavoriteOf/' + this.userEmailID) ? colleague['isFavorite'] = true : colleague['isFavorite'] = false
          //colleague.hasChild('isFavoriteOf/' + this.userEmailID) ? colleague['isFavorite'] = true : colleague['isFavorite'] = false
          colleague['realRole'] = this.realRole[colleague['role']]
          if (colleague['role'].toString() == "1,5") { colleague['realRole'] = "Utilisateur sans photo" }
          //console.log(colleague['emailAddress'],this.totalPeriod[colleague['emailAddress']])
          if (this.totalPeriod[colleague['emailAddress']] != undefined) {
            colleague['totalPeriods'] = this.totalPeriod[colleague['emailAddress']].toString()

          }
          else {
            colleague['totalPeriods'] = "0"
          }
          //console.log(colleague)
          this.colleagues.push(colleague as Colleague);

        })
      })

    });
  }



  // Using valueChanges() method to fetch simple list of colleagues data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState() {
    this.colleaguesApi.GetColleaguesList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoColleague = false;
        this.noData = true;
      } else {
        this.hideWhenNoColleague = true;
        this.noData = false;
      }
    })
  }

  // Method to delete colleagues object
  DeleteColleague(colleague) {
    if (Number(this.localUserData.role) < Number(colleague.role)) return
    //console.log(colleague.colleagueEmailID)
    if (window.confirm('Etes-vous "certes zinzin" de vouloir effacer ce collègue ?')) { // Asking from user before Deleting colleagues data.
      this.colleaguesApi.DeleteColleague(colleague.colleagueEmailID)
      // Using Delete colleagues API to delete colleagues.
      this.toastr.success('Le collègue ' + colleague.firstName + ' ' + colleague.lastName + ' est bien effacé pour toujours à jamais, tant pis pour votre amitié !'); // Alert message will show up when colleagues successfully deleted.
    }
  }


  setSortParams(param) {
    let vsKeys = Object.keys(this.viewSearch)
    vsKeys.forEach(element => {
      //console.log(element)
      if (element != param.column) {
        this.viewSearch[element] = false
      }

    });
    //console.log(param)
    this.mode = param.mode;
    this.column = param.column;
    this.type = param.type;
    //this.searchTerm = thi
  }

  startFilter(column) {
    let vsKeys = Object.keys(this.viewSearch)
    vsKeys.forEach(element => {
      //console.log(element)
      this.viewSearch[element] = false
    });
    //console.log(column)
    this.mode = ""
    this.column = column;
   
    this.viewSearch[column] = true

  }

  endFilter(column) {
    this.searchTerm[column] = ""
    this.viewSearch[column] = false

  }

  triggerModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), error.message)
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  dataStateTC(data) {

    this.preLoader = false;
    if (data.length <= 0) {
      this.hideWhenNoTravCol = false;
      this.noData = true;
    } else {
      this.hideWhenNoTravCol = true;
      this.noData = false;
    }

  }



}