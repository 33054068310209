<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<body id="full-height" >



  <div *ngIf="parameters" style="overflow-x:auto" style="page-break-after: always;padding-top: 100px;vertical-align: middle;height: 100%;">
  <table scrollx="true" style="margin-left:auto;margin-right:auto;min-width: 100%; background-color: white; border: none;" >
      
  <tbody style="border:none" >
   
    
      <td class="center" style="border:none;width:50%; padding-left: 50px;">
        <img src="{{parameters['coverSheetPicture'] }}"width="80%">
      </td>
      <td class="center" style="border:none;width:50%; padding-right: 50px;">
        <img src="{{parameters['schoolLogo']}}"width="40%">
        <h1 style="color: black; margin-right: 25px;">{{parameters['schoolName'] }}  <br> <br>
        Travail collaboratif   <br> <br> 
        Année scolaire {{giveSchoolYear()}} 
       <div style="font-size: 10pt;"> Date d'expiration des travaux : {{maxDate}}</div> <br>
      
       
        <strong> {{localUserData.displayName}}</strong> ({{localUserData.quota}})  </h1>
         <br>
        <div *ngIf="parameters['calculateTotalInPrint']">
          <img
                  *ngIf="getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes/5]"
                  src="./assets/images/ProgressBar1.png" style="width: 100%;" />
                <img
                  *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*2/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes*2/5]"
                  src="./assets/images/ProgressBar2.png" style="width: 100%;" />
                <img
                  *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes*2/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*3/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes*3/5]"
                  src="./assets/images/ProgressBar3.png" style="width: 100%;" />
                <img
                  *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes*3/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*4/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes*4/5]"
                  src="./assets/images/ProgressBar4.png" style="width: 100%;" />
                <img
                  *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes*4/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*5/5]"
                  src="./assets/images/ProgressBar5.png" style="width: 100%;" />
                <img
                  *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes*5/5]|| getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes*5/5]"
                  src="./assets/images/ProgressBar6.png" style="width: 100%;" />
          <div class="progress" style="background-color: #5b6570; margin-top: 10px;">
            <div class="progress-bar progress-bar-striped progress-primary" role="progressbar"
              [ngStyle]="{ 'width': getTotal(travcol)['totalPourcent']+'%'}" aria-valuemin="0"
              aria-valuemax="100"> </div>
        </div>
        Total = {{getTotal(travcol)['totalMinute']}} minute<span
        *ngIf="getTotal(travcol)['totalMinute']&gt;1">s</span> ou {{getTotal(travcol)['totalPeriode']}}
      période<span *ngIf="getTotal(travcol)['totalPeriode']&gt;1">s</span> de 50 minutes.
      </div>
    </td>
    

   
  </tbody>
</table>

</div>

<div *ngIf="parameters" style="overflow-x:auto;overflow-y: hidden;">
    <table scrollx="true" scrolly="false" style="margin-left:auto;margin-right:auto;min-width: 100%; background-color: white;" >
      
        <tr >
          <th class="center">Date</th>
          <th class="center">Durée</th>
          <th class="center">Collègues</th>
          <th class="center" *ngIf="parameters['TCType']">Type</th>
          <th class="center" >Objectifs</th>
          <th class="center" *ngIf="parameters['TCDecisions']" >Décisions</th>
         
        </tr>
      
      <tbody >
        <!-- *ngFor loop iterates over TravCol array and fetch the travcol's data -->
        <!-- paginate pipe will add pagination in travcol's list, it won't show if items are less then 7 -->
        <tr *ngFor="let travcol of sortBy(this.sort)">
                    <td *ngIf="travcol.isActivable" class="center" style="font-weight: bold;" [ngStyle]="{
                      'color'
                      : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                      : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable)? 'var(--disabledPrintText)' 
                      : 'black' 
                    ,
                      'background-color'
                      : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--bgAccepted)'
                      : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable)? 'var(--disabledPrintBg)' 
                      : 'white' 
                    }"
                    
                    >
                      {{travcol.date |
                      date:'dd/MM/yyyy'}}</td>
                    <td *ngIf="travcol.isActivable" class="center" style="font-weight: bold;" [ngStyle]="{
                        'color'
                        : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                        : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabledPrintText)' 
                        : 'black',
                     
                        'background-color'
                        : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--bgAccepted)'
                        : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabledPrintBg)' 
                        : 'white' 
                      }"
                      >
                      {{travcol.duration}}</td>
                    <td *ngIf="travcol.isActivable"  style="font-weight: bold;"    
                      [ngStyle]="{
                        'background-color' 
                        : !travcol.isActivable ? 'var(--disabledPrintBg)'
                        : travcol['colleaguesOfTravcol'][localUserData.emailID]['hasActivated'] ?  'white':'var(--disabledPrintBg)'}"           
                   >

                      <ng-container *ngFor="let colleagues of travcol['colleaguesOfTravcol2'] ">

                      <li *ngIf="travcol.colleaguesOfTravcol[colleagues.key]&&travcol.colleaguesOfTravcol[colleagues.key]['hasValidated']" [ngStyle]="{
                       

                        'color'
                        : !travcol.isActivable ? 'var(--disabledPrintText)'
                        : travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] && !travcol.colleaguesOfTravcol[colleagues.key]['hasActivated'] ? 'var(--disabledPrintText)' 
                        : !travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] ? 'var(--accepted)'
                        : travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] && (!travcol.colleaguesOfTravcol[colleagues.key]['hasActivated']|| !travcol.isActivable) ? 'var(--disabledPrintText)' 
                        : 'black'
                      }"
                      
        
                      
                      >
                        {{colleagues.fullName}}<!-- =IA:{{travcol.isActivable}},HA:{{colleagues.hasActivated}},HV:{{colleagues.hasValidated}}-->
                      </li>
                    </ng-container>
                    </td>
                    <td *ngIf="travcol.isActivable&&parameters['TCType']" style="font-weight: bold; ;" [ngStyle]="{
                      'color'
                      : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                      : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabledPrintText)' 
                      : 'black' ,


                      'background-color'
                    : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--bgAccepted)'
                    : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable)? 'var(--disabledPrintBg)' 
                    : 'white' 
                    }">
                    {{travcol.typeOfTC}}
                  </td>

                    <td *ngIf="travcol.isActivable" style="font-weight: bold; ;" [ngStyle]="{
                        'color'
                        : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                        : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabledPrintText)' 
                        : 'black' ,


                        'background-color'
                      : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--bgAccepted)'
                      : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable)? 'var(--disabledPrintBg)' 
                      : 'white' 
                      }">
                      {{travcol.goals}}
                    </td>
                    <td *ngIf="travcol.isActivable&&parameters['TCDecisions']"style="font-weight: bold;" [ngStyle]="{
                        'color'
                        : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--Accepted)'
                        : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabledPrintText)' 
                        : 'black' ,
                        'background-color'
                      : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--bgAccepted)'
                      : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable)? 'var(--disabledPrintBg)' 
                      : 'white' 
                      }">
                      {{travcol.decisions}}</td>
                   
                   

                  </tr>
      </tbody>
    </table>
    
   </div>

    
</body>


