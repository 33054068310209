<header class="page-header" id="header">
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom"
  style="margin-top: 100px; margin-bottom: 10px;">
  <h1 style="color: white; margin-right: 25px;">Liste des travaux en attente </h1>
  <!-- It won't show if there is no travcol data -->
  <!--a routerLink="/add-travcol" class="btn btn-secondary" style="margin-bottom: 15px; margin-right: 30px;"
    *ngIf="hideWhenNoTravCol">
    Ajouter un TC
    <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span
        class="path2"></span></span>
  </a>
  <a routerLink="/travcols-print" class="btn btn-secondary" style="margin-bottom: 15px;padding-top: 8px;"
    *ngIf="hideWhenNoTravCol">
    Imprimer les TC
    <span class="icon-iconPrint" style="font-size: 1.5em;"><span class="path1"></span><span class="path2"></span></span>

  </a-->

</div>

<div class="pricing-header mx-auto">

  <div class="wrapper" *ngIf="noData">
    <div class="cards_wrap">
      <div class="card_item" style="margin-bottom: 20px;">
        <div class="card_inner">
          <div class="card_top center">

          </div>
          <div class="card_bottom">

            <div class="card_info">

              <div class="no-data text-center" *ngIf="preLoader">
                <img src="assets/preloader.svg" class="preloader-icon" alt="No travcol">
              </div>

              <div class="center">
                <img src="assets/images/TCToBeValidated.png" width="60%" height="60%" class="nodata-msg" alt="">
              </div>
              <br>
              Pas de travail collaboratif en attente, donc
              <ul style="padding-left: 35px">
                <li>Soit vos collègues vous ont encore oublié(e)</li>
                <li>Soit vous avez déjà accepté tous vos travaux</li>
              </ul>





            </div>
            <div class="card_creator">

              <button routerLink="/travcols-list" class="btn btn-primary" style="margin-right:10px">
                TC acceptés
                <span class="icon-iconTCValides" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>


              </button>
              <button *ngIf="localUserData &&!localUserData.isInvite" routerLink="/add-travcol" class="btn btn-primary">
                Ajouter un TC
                <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>

              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>











  <div class="wrapper" style="min-width: 100%;" *ngIf="hideWhenNoTravCol">
    <div class="cards_wrap" style="min-width: 100%;">
      <div class="card_item" style="min-width: 100%;margin-bottom: 20px;">
        <div class="card_inner" style="min-width: 100%;">
          <div style="text-align: right;margin-bottom: -30px;">
            <button class="btn" (click)="triggerModal(modalDataTravColListe)">
              <span ngbTooltip="Aide" class="icon-iconInfo" style="font-size: 1.4em;"></span>
            </button>
          </div>
          <div class="card_top center" style="min-width: 100%;">
          </div>
          <div class="card_bottom" style="min-width: 100%;">
            <div class="card_info" style="min-width: 100%;">
              <!-- Showing travcols data -->
              <div style="overflow-x:auto" id="pdfTable">
                <table scrollx="true" style="margin-left:auto;margin-right:auto;min-width: 100%;margin-bottom: 20px;">

                  <tr>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['date']"> Date </span>
                      <span *ngIf="!this.viewSearch['date']" (click)="startFilter('date')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['date']" [(ngModel)]="searchTerm['date']" class="search"
                        placeholder="Chercher une date">


                      <span *ngIf="this.viewSearch['date']" (click)="endFilter('date')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'date', type: 'string', searchTerm: searchTerm['date']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='date' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='date' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'date', type: 'string', searchTerm: searchTerm['date']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='date' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='date' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>


                    <th class="center">
                      <span *ngIf="!this.viewSearch['duration']"> Durée </span>
                      <span *ngIf="!this.viewSearch['duration']" (click)="startFilter('duration')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['duration']" [(ngModel)]="searchTerm['duration']" class="search"
                        placeholder="Chercher une durée">


                      <span *ngIf="this.viewSearch['duration']" (click)="endFilter('duration')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'duration', type: 'number', searchTerm: searchTerm['duration']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='duration' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='duration' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'duration', type: 'number', searchTerm: searchTerm['duration']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='duration' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='duration' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>

                    <th class="center" *ngIf="parameters['TCType']">

                      <span *ngIf="!this.viewSearch['colleaguesListString']"> Collègues </span>
                      <span *ngIf="!this.viewSearch['colleaguesListString']" (click)="startFilter('colleaguesListString')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['colleaguesListString']" [(ngModel)]="searchTerm['colleaguesListString']" class="search"
                        placeholder="Chercher un type de TC">


                      <span *ngIf="this.viewSearch['colleaguesListString']" (click)="endFilter('colleaguesListString')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'colleaguesListString', type: 'string', searchTerm: searchTerm['colleaguesListString']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='colleaguesListString' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='colleaguesListString' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'colleaguesListString', type: 'string', searchTerm: searchTerm['colleaguesListString']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='colleaguesListString' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='colleaguesListString' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center" *ngIf="parameters['TCType']">

                      <span *ngIf="!this.viewSearch['typeOfTC']"> Type </span>
                      <span *ngIf="!this.viewSearch['typeOfTC']" (click)="startFilter('typeOfTC')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['typeOfTC']" [(ngModel)]="searchTerm['typeOfTC']" class="search"
                        placeholder="Chercher un type de TC">


                      <span *ngIf="this.viewSearch['typeOfTC']" (click)="endFilter('typeOfTC')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'typeOfTC', type: 'string', searchTerm: searchTerm['typeOfTC']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='typeOfTC' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='typeOfTC' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'typeOfTC', type: 'string', searchTerm: searchTerm['typeOfTC']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='typeOfTC' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='typeOfTC' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>

                    <th class="center">
                      <span *ngIf="!this.viewSearch['goals']"> Objectifs </span>
                      <span *ngIf="!this.viewSearch['goals']" (click)="startFilter('goals')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['goals']" [(ngModel)]="searchTerm['goals']" class="search"
                        placeholder="Chercher un objectif">


                      <span *ngIf="this.viewSearch['goals']" (click)="endFilter('goals')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'goals', type: 'string', searchTerm: searchTerm['goals']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='goals' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='goals' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'goals', type: 'string', searchTerm: searchTerm['goals']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='goals' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='goals' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center" *ngIf="parameters['TCDecisions']">

                      <span *ngIf="!this.viewSearch['decisions']"> Décisions </span>
                      <span *ngIf="!this.viewSearch['decisions']" (click)="startFilter('decisions')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['decisions']" [(ngModel)]="searchTerm['TCDecisions']" class="search"
                        placeholder="Chercher une décision">


                      <span *ngIf="this.viewSearch['decisions']" (click)="endFilter('decisions')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'decisions', type: 'string', searchTerm: searchTerm['TCDecisions']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='decisions' && mode =='asc')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='decisions' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'decisions', type: 'string', searchTerm: searchTerm['TCDecisions']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='decisions' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='decisions' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center"><span *ngIf="!this.viewSearch['initials']" ngbTooltip="Géniteur"
                        class="icon-iconProfil" style="font-size: 1.4em;"><span class="path1"></span><span
                          class="path2"></span></span>

         
                            <span *ngIf="!this.viewSearch['initials']" (click)="startFilter('initials')">
                              <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                                style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                                  class="path2"></span><span class="path3"></span></span>
                            </span>
      
                            <input *ngIf="this.viewSearch['initials']" [(ngModel)]="searchTerm['initials']" class="search"
                              placeholder="Chercher une initiale">
      
      
                            <span *ngIf="this.viewSearch['initials']" (click)="endFilter('initials')">
                              <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                                style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                                  class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                            </span>
                            <span
                              (click)="setSortParams({mode: 'asc', column: 'initials', type: 'string', searchTerm: searchTerm['initials']})">
                              <span ngbTooltip="A -> Z" *ngIf="!(column=='initials' && mode =='asc')"
                                class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                              <span ngbTooltip="A-> Z" *ngIf="column=='initials' && mode =='asc'"
                                class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                  class="path1"></span><span class="path2"></span></span>
                            </span>
      
      
                            <span
                              (click)="setSortParams({mode: 'dsc', column: 'initials', type: 'string', searchTerm: searchTerm['initials']})">
                              <span ngbTooltip="Z -> A" *ngIf="!(column=='initials' && mode =='dsc')"
                                class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                              <span ngbTooltip="Z -> A" *ngIf="column=='initials' && mode =='dsc'"
                                class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                  class="path1"></span><span class="path2"></span></span>
                            </span>
      
                          </th>                   
                          <th class="center"><span class="icon-iconInscription" style="font-size: 1.3em;"><span
                            class="path1"></span><span class="path2"></span></span></th>
                  



                  </tr>

                  <tbody *ngIf="hideWhenNoTravCol">
                    <!-- *ngFor loop iterates over travcol array and fetch the travcol's data -->
                    <!-- paginate pipe will add pagination in travcol's list, it won't show if items are less then 7 -->
                    <tr *ngFor="let travcol of travcols 
                    |filter:column:searchTerm[column]
                    |sort:mode:column:type
                    |paginate: { itemsPerPage: 10, currentPage: p };                  
                     let i = index;">
                      <td class="center" style="font-weight: bold;"
                        [ngStyle]="{'color':travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] === true ? 'white' : 'var(--accepted)' }">
                        {{travcol.date |
                        date:'dd/MM/yyyy'}}</td>
                      <td class="center" style="font-weight: bold;"
                        [ngStyle]="{'color':travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] === true ? 'white' : 'var(--accepted)' }">
                        {{travcol.duration}}</td>
                      <td style="font-weight: bold;">

                        <li *ngFor="let colleagues of travcol['colleaguesOfTravcol2']"
                          [ngStyle]="{'color':travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] ? 'white' : 'var(--accepted)' }">
                          {{colleagues.fullName}}
                        </li>

                        <!--li *ngFor="let colleagues of travcol.test " >{{colleagues.fullName}}
                          
                        </li-->

                      </td>
                      <td *ngIf="parameters['TCType']" style="font-weight: bold; ;"
                        [ngStyle]="{'color':travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] === true ? 'white' : 'var(--accepted)' }">
                        {{travcol.typeOfTC}}
                      </td>
                      <td style="font-weight: bold; ;"
                        [ngStyle]="{'color':travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] === true ? 'white' : 'var(--accepted)' }">
                        {{travcol.goals}}
                      </td>
                      <td *ngIf="parameters['TCDecisions']" style="font-weight: bold;"
                        [ngStyle]="{'color':travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] === true ? 'white' : 'var(--accepted)' }">
                        {{travcol.decisions}}</td>
                      <td class="center" style="font-weight: bold;"
                        [ngStyle]="{'color':travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] === true ? 'white' : 'var(--accepted)' }">
                        <a ngbTooltip={{travcol.fullName}}>{{travcol.initials}}</a>
                      </td>
                      <!--td class="center">
                        
                        <span  class="icon-iconEdit" style="font-size: 1.5em;"
                          [routerLink]="['/edit-travcol',travcol.userEmailID,travcol.ownerID,travcol.key]"></span>
                          

                        <span  class="icon-iconSupprimer" style="font-size: 1.5em;"
                          (click)="deleteChoice(modalChoice,travcol)"><span class="path1"></span><span
                            class="path2"></span></span>
                      </td-->


                      <td class="center">
                        <!--a style="color: transparent;" href={{travcol.PDFJoin}}>
                          <span ngbTooltip="Pièce jointe"
                          *ngIf="parameters['PDFJoin']&&travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']"
                          class="icon-iconPDF" style="font-size: 1.5em;cursor:pointer"></span>
                         </a-->

                        <span ngbTooltip="Se retirer" class="icon-iconSupprimer"
                          style="font-size: 1.5em;cursor: pointer;" (click)="removeFromTravcol(travcol)"><span
                            class="path1"></span><span class="path2"></span></span>
                        <span ngbTooltip="Accepter" class="icon-iconValiderPouce"
                          style="font-size: 1.5em; padding: none; margin:none;cursor: pointer;"
                          (click)="putHasValidated(travcol)">
                          <span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                      </td>



                    </tr>
                  </tbody>


                </table>

                <div class="card_creator" style="margin-top: 10px;">
                  <pagination-controls class="my-pagination" (pageChange)="p = $event" autoHide="true" responsive="true"
                    previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>
                  <!--a routerLink="/travcols-print" class="btn btn-secondary"
                    style="margin-bottom: 15px;padding-top: 8px;" *ngIf="hideWhenNoTravCol">
                    Imprimer les TC
                    <span class="icon-iconPrint" style="font-size: 1.5em;"><span class="path1"></span><span
                        class="path2"></span></span>

                  </a-->
                </div>



                <!--br>
                <img *ngIf="getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes/5]"
                  src="./assets/images/ProgressBar1.png" style="width: 100%;" />
                <img
                  *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*2/5] || getTotal(travcol)['totalMinute'] ==this.userQuotaInMinutes*2/5"
                  src="./assets/images/ProgressBar2.png" style="width: 100%;" />
                <img
                  *ngIf="getTotal(travcol)['totalMinute']&gt;this.userQuotaInMinutes*2/5 && getTotal(travcol)['totalMinute']&lt;this.userQuotaInMinutes*3/5 || getTotal(travcol)['totalMinute'] ==this.userQuotaInMinutes*3/5"
                  src="./assets/images/ProgressBar3.png" style="width: 100%;" />
                <img
                  *ngIf="getTotal(travcol)['totalMinute']&gt;this.userQuotaInMinutes*3/5 && getTotal(travcol)['totalMinute']&lt;this.userQuotaInMinutes*4/5 || getTotal(travcol)['totalMinute'] ==this.userQuotaInMinutes*4/5"
                  src="./assets/images/ProgressBar4.png" style="width: 100%;" />
                <img *ngIf="getTotal(travcol)['totalMinute']&gt;this.userQuotaInMinutes*4/5 && getTotal(travcol)['totalMinute']&lt;this.userQuotaInMinutes"
                  src="./assets/images/ProgressBar5.png" style="width: 100%;" />
                <img *ngIf="getTotal(travcol)['totalMinute']&gt;this.userQuotaInMinutes || getTotal(travcol)['totalMinute'] ==this.userQuotaInMinutes"
                  src="./assets/images/ProgressBar6.png" style="width: 100%;" />
                <div class="progress" style="background-color: #5b6570; margin-top: 10px;">
                  <div class="progress-bar progress-bar-striped progress-primary" role="progressbar"
                    [ngStyle]="{ 'width': getTotal(travcol)['totalPourcent']+'%'}" aria-valuemin="0"
                    aria-valuemax="100"> </div>
                </div-->

              </div>
              <!--<div> <button  (click)="downloadAsPDF()">Export To PDF</button></div>   -->


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>



<ng-template #modalChoice let-modal>
  <div class="modal-header">
    <div class="modal-title " style="font-size: 15pt;margin: auto;" id="modal-basic-title">Effacement des TC</div>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;">

    <p><strong>Quand un TC vous appartient, vous l'effacez pour tous
        les collègues qui y sont liés et pour vous-même. En d'autres termes, le TC disparaît. </strong> </p>
    <p><strong>Par contre, quand un TC ne vous appartient pas, vous vous retirez de la liste des collègues de ce TC.
      </strong> </p>
    <p><strong>Et dans ce cas-ci, ce TC <span *ngIf="!this.isOwnerOfTC">ne vous appartient pas </span> <span
          *ngIf="this.isOwnerOfTC">vous appartient </span> donc : </strong> </p>



    <ul style="padding-left: 25px;">
      <li> Si vous voulez <strong>modifier</strong> la liste des collègues liés à ce TC, veuillez cliquer sur
        "Modifier".</li>
      <li *ngIf="!isOwnerOfTC"> Si vous voulez <strong>vous retirer</strong> ce TC mais que pour vous seul, veuillez
        cliquer sur "Se retirer".Et tant pis pour votre quota! Na!</li>
      <li *ngIf="isOwnerOfTC"> Si vous voulez <strong>effacer ce TC </strong> (vos collègues et vous-même), veuillez
        cliquer sur "Effacer".</li>
      <li> Si ne ne voulez rien faire, veuillez cliquer sur "Annuler". </li>
    </ul>





  </div>
  <div class="modal-footer" style="font-size: 12pt; margin-top: -20px;">
    <button type="button" class="btn btn-danger" style="font-size: 12pt;" (click)="modal.close('Modifier')">Modifier
      <span class="icon-iconEdit" style="font-size: 1.5em;"></span>
    </button>

    <button *ngIf="!isOwnerOfTC" type="button" class="btn btn-danger" style="font-size: 12pt;"
      (click)="modal.close('Effacer')">Se retirer
      <span class="icon-iconSupprimer" style="font-size: 1.5em;"><span class="path1"></span><span
          class="path2"></span></span>
    </button>

    <button *ngIf="isOwnerOfTC" type="button" class="btn btn-danger" style="font-size: 12pt;"
      (click)="modal.close('Effacer')">Effacer
      <span class="icon-iconExterminate" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span><span class="path3"></span></span>
    </button>

    <button type="button" class="btn btn-danger" style="font-size: 12pt;" (click)="modal.close('Annuler')">Annuler
      <span class="icon-iconRetour" style="font-size: 1.3em;"></span>
    </button>
  </div>
</ng-template>


<ng-template #modalDataTravColListe let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Pas de panique !</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;margin-bottom: -20px;">
    Dans cette liste, vous trouvez les travaux collaboratifs que vos collègues ont encodés pour vous, quelle chance !
    <br>
    Avec ces travaux vous pouvez soit :
    <ul style="padding-left: 35px;">
      <li>vous retirer <span class="icon-iconSupprimer" style="font-size: 1.4em;"><span class="path1"></span><span
            class="path2"></span></span> de ce travail,
      </li>
      <li>accepter <span class="icon-iconValiderPouce" style="font-size: 1.5em; padding: none; margin:none;">
          <span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
        ce travail. </li>

    </ul>
    Si vous vous retirez, le travail disparaîtra définitivement de vos listes personnelles.
    Par contre, votre travail, si toutefois vous l'acceptez, s'autodétruira de cette liste pour réapparaître
    dans la liste des travaux acceptés.
    <br><br>
    Code couleurs pour les noms des collègues :

    <ul style="padding-left: 35px;">
      <li> <strong style="color:var(--fushia)"> Fushia</strong> : TC pas encore accepté par ce collègue,
      </li>
      <li> <strong> Blanc</strong> : TC accepté et comptabilisé par ce collègue,
      </li>

      <li> <strong style="color:#73808d"> Gris</strong> : TC accepté mais non comptabilisé chez ce collègue.
      </li>

    </ul>
    <span style="text-decoration: underline;"> Remarques</span> :
    <ul style="padding-left: 35px;">
      <li> Dans cette liste il n'y a que des travaux couleur <strong style="color:var(--fushia)">fushia</strong>
        car aucun d'entre eux n'est encore accepté.
      </li>
      <li> Quand vous aurez accepté ou retiré tous vos travaux, Expresso l'escargot apparaîtra pour vous féliciter.
        Le but du jeu est de faire passer les travaux de cette liste vers celle des TC validés.
      </li>
      <li> Il est possible de filtrer <span class="icon-iconFiltreOk" style="font-size: 1.4em;"><span
            class="path1"></span><span class="path2"></span><span class="path3"></span></span> certaines colonnes de la
        liste.

      </li>



    </ul>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Fermer
      <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>
    </button>
  </div>
</ng-template>