<div class="bg-dark" style="position: fixed;
width:100%;
right: 0;
top: 0;
background-position: top;
z-index: 3;
">
  <div class="container">
    <div class="row">

      <nav class="col navbar navbar-expand-lg navbar-dark">

        <a href="http://www.bosscool.be" *ngIf="this.router.url!='/travcols-print'">
          <span style="cursor:pointer">
 
            <img src="./assets/images/HeaderBosscool.png" width="135px" height="45px" />
          </span>
        </a>




        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          *ngIf="localUserData &&localUserData.isLogged && this.router.url!='/travcols-print'">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarContent" class="collapse navbar-collapse"
          *ngIf="localUserData &&localUserData.isLogged && this.router.url!='/travcols-print'">

          <ul class="navbar-nav">
            <li ngbDropdown class="nav-item dropdown" style="margin-top: 1.8px; cursor: pointer;">
              <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" aria-haspopup="true"
                aria-expanded="false" *ngIf="localUserData &&localUserData.isLogged">
                <span class="icon-iconTravaux" style="font-size: 1.3em;"><span class="path1"></span><span class="path2">
                  </span><span class="path3"></span><span class="path4"></span></span>
                Travaux
              </a>
              <div ngbDropdownMenu class="dropdown-menu bg-dark " aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item">
                <span class="icon-iconTravauxListe" style="font-size: 1.8em;"><span class="path1"></span><span
                    class="path2"></span></span>
                Liste &raquo; </a>
              <ul class="submenu dropdown-menu ">
                <li><a class="dropdown-item" routerLink="/travcols-to-be-validated" routerLinkActive="active">
                    <span class="icon-iconTCAValider" style="font-size: 1.8em;"><span class="path1"></span><span
                        class="path2"></span><span class="path3"></span></span>

                    En attente</a></li>

                <li><a class="dropdown-item" routerLink="/travcols-list" routerLinkActive="active">
                    <span class="icon-iconTCValides" style="font-size: 1.8em;"><span class="path1"></span><span
                        class="path2"></span></span>


                    Accepté(s)</a></li>
              </ul>
            </li>

            <a *ngIf="localUserData &&!localUserData.isInvite" class="dropdown-item" routerLink="/add-travcol"
              routerLinkActive="active">
              <span class="icon-iconAjouter" style="font-size: 1.8em;"><span class="path1"></span><span
                  class="path2"></span></span>
              Ajouter</a>

            <a *ngIf="localUserData &&!localUserData.isInvite" class="dropdown-item" routerLink="/travcols-print"
              routerLinkActive="active">
              <span class="icon-iconPrint" style="font-size: 1.8em;"><span class="path1"></span><span
                  class="path2"></span></span>
              Imprimer</a>

        </div>
        </li>
        <!--<li ngbDropdown class="nav-item dropdown ">
              <a ngbDropdownToggle class="nav-link dropdown-toggle " href="#" id="navbarDropdownMenuLink"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="localUserData &&localUserData.isLoggedIn">
                Collègues
              </a>
              <div ngbDropdownMenu class="dropdown-menu bg-dark" style="width:20%"
                aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" routerLink="/admin-colleagues-list">
                  <span class="icon-iconTravauxListe" style="font-size: 1.8em;"><span class="path1"></span><span class="path2"></span></span>
                  Liste</a>
                <a class="dropdown-item" routerLink="/register-colleague">
                  <span class="icon-iconAjouter" style="font-size: 2em;"><span class="path1"></span><span
                      class="path2"></span><span class="path3"></span></span>
                  Ajouter</a>
     
  
              </div>
            </li> -->

        <li ngbDropdown class="nav-item dropdown" style="margin-top: 1.8px; cursor: pointer;">
          <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" aria-haspopup="true"
            aria-expanded="false" *ngIf="localUserData &&localUserData.isLogged &&!localUserData.isInvite">

            <span class="icon-iconCollegues" style="font-size: 1.3em;"><span class="path1"></span><span
                class="path2"></span><span class="path3"></span></span>
            Collègues
          </a>
          <div ngbDropdownMenu class="dropdown-menu bg-dark " aria-labelledby="navbarDropdownMenuLink">
            <a  *ngIf="localUserData.role>1.5" class="dropdown-item" style="vertical-align: center;" routerLink="/tronchesbinoscope"
              routerLinkActive="active">
              <span class="icon-iconTronches" style="font-size: 2.3em;"><span class="path1"></span><span
                  class="path2"></span></span>
              Tronches</a>
            <a class="dropdown-item" routerLink="/colleagues-list" routerLinkActive="active">
              <span class="icon-iconFavoris" style="font-size: 2.3em;"><span class="path1"></span><span
                  class="path2"></span><span class="path3"></span></span>

              Favoris</a>

            <a class="dropdown-item" routerLink="/invite-colleagues" routerLinkActive="active">
              <span class="icon-iconInviter" style="font-size: 2.3em;"><span class="path1"></span><span
                  class="path2"></span><span class="path3"></span></span>

              Inviter</a>

          </div>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLink="/profile" routerLinkActive="active"
            *ngIf="localUserData &&localUserData.isLogged">
            <span class="icon-iconProfil" style="font-size: 1.4em;"><span class="path1"></span><span
                class="path2"></span></span>
            {{localUserData &&localUserData.shortName }}</a>


        </li>




        </ul>



        <ul class="navbar-nav ml-auto">
          <li ngbDropdown class="nav-item dropdown" style="cursor: pointer;">
            <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" *ngIf="localUserData &&localUserData.isAdmin">
              <span class="icon-iconAdmin" style="font-size: 1.5em;"><span class="path1"></span><span
                  class="path2"></span><span class="path3"></span></span>
              Admin </a>
            <ul class="dropdown-menu bg-dark">

              <li><a class="dropdown-item" routerLink="/parameters" routerLinkActive="active">

                  <span class="icon-iconParametres" style="font-size: 2.1em;"></span>
                  Paramètres </a></li>

              <li><a class="dropdown-item">
                  <span class="icon-iconCollegues" style="font-size: 1.8em;"><span class="path1"></span><span
                      class="path2"></span><span class="path3"></span></span>
                  Collègues &raquo; </a>
                <ul class="submenu dropdown-menu ">
                  <li><a class="dropdown-item" routerLink="/admin-colleagues-list" routerLinkActive="active">
                      <span class="icon-iconTravauxListe" style="font-size: 1.8em;"><span class="path1"></span><span
                          class="path2"></span></span>
                      Liste</a></li>

                  <li><a class="dropdown-item" routerLink="/add-colleague" routerLinkActive="active">
                      <span class="icon-iconAjouter" style="font-size: 1.8em;"><span class="path1"></span><span
                          class="path2"></span></span>
                      Ajouter</a></li>

                  <li><a *ngIf="localUserData.role>3" class="dropdown-item" routerLink="/substitute"
                      routerLinkActive="active">

                      Réincarner</a></li>

                </ul>

              </li>

              <li><a *ngIf="localUserData.role>3" class="dropdown-item" routerLink="/all-travcols"
                  routerLinkActive="active">

                  Tous TC </a></li>


              <!--<li><a class="dropdown-item" routerLink="#" routerLinkActive="active"> Rapports </a></li>-->
            </ul>
          </li>
        </ul>
        <ul class="bg-dark navbar-dark navbar-dark navbar-nav nav-link">
          <li class="nav-item">
            <a class="nav-link"
              *ngIf="localUserData &&localUserData.isLogged&&!localUserData.isInvite && this.router.url!='/travcols-print'"
              routerLink="/edit-info" routerLinkActive="active">
              <span class="icon-iconAide" style="font-size: 1.7em;margin-bottom: -200px;"></span>
              Info</a>

          </li>
        </ul>

        <button type="button" class="btn btn-secondary" style="margin-top: 5px;margin-bottom: 5px;"
          (click)="onSignOut()" *ngIf="localUserData &&localUserData.isLogged && this.router.url!='/travcols-print'">
          Déconnexion {{customer}}
          <i class="icon-iconDeconnexion" style="font-size: 1.3em;"></i>
         

        </button>

    </div>






    <!-- <button type="button" class="btn btn-secondary"
          (click)="onSignIn()" *ngIf="!localUserData &&localUserData.isLoggedIn">
          Connexion
        <span class="icon-iconConnexionNew"style="font-size: 1.3em;"></span>  
        </button>-->

    <div id="navbarContent" class="collapse navbar-collapse " style="padding: 0px;">
      <button type="button" class="btn btn-secondary" style="margin-top: 5px;margin-bottom: 5px;" (click)=" onBack()"
        *ngIf="localUserData &&localUserData.isLogged && this.router.url=='/travcols-print'">
        Retour
        <i class="icon-iconRetour" style="font-size: 1.3em;"></i>
      </button> <!-- affiche uniquement sur travcolprint-->
    </div>
    <button type="button" class="btn btn-secondary" style="margin-top: 5px;margin-bottom: 5px;" (click)="PrintPage()"
      *ngIf="localUserData &&localUserData.isLogged && this.router.url=='/travcols-print'">
      Imprimer
      <i class="icon-iconPrint" style="font-size: 1.5em;"><span class="path1"></span><span class="path2"></span></i>
    </button> <!-- affiche uniquement sur travcolprint-->






    </nav>

  </div>
</div>
</div>

<!--<nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <div class="container"> <a class="navbar-brand" href="#">

        <div class="TitleBossCool" > BossCool </div>
      </a>
      <img src="./../../assets/images/bosscool.svg" width="70" height ="70"/>
      <button class="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse"
        data-target="#navbar10">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbar10">
        <ul class="navbar-nav ml-auto">
          
          <li class="nav-item">
            <a class="nav-link" routerLink="/profile" routerLinkActive="active">
              <i class="fas fa-user"></i>User Profile
            </a>
          </li>
  
          <li class="nav-item">
            <a class="nav-link" routerLink="/view-students" routerLinkActive="active">
              <i class="fas fa-list-ul"></i>Students List
            </a>
          </li>
  
  
          <li class="nav-item">
            <a class="nav-link" routerLink="/register-student" routerLinkActive="active">
              <i class="fas fa-plus"></i>Add Student
            </a>
          </li>
          
          <li class="nav-item">
            <a class="nav-link" routerLink="/add-travcol" routerLinkActive="active">
              <i class="fas fa-plus"></i>Add TC
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/travcols-list" routerLinkActive="active">
              <i class="fas fa-plus"></i>TC List
            </a>
          </li>


        </ul>

        
      </div>
    
        <button type="button" class= "btnClassic" style="width:12%" (click)="authservice.SignOut()">
          Logout
          <i class="fas fa-sign-out-alt"></i> 
        </button>
    </div>
    
  </nav>-->