
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ParametersService } from '../parameters.service';
import { Parameters } from '../parameters';
import { ToastrService } from 'ngx-toastr';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit {

  public ParametersForm: FormGroup;
  disabled = false;
  todayDate: Date = new Date();
  ShowFilter = false;
  limitSelection = false;
  dropdownSettings: any = {};
  Parameter: Parameters[];                 // Save students data in Student's array.
  hideWhenNoParameter: boolean = false; // Hide students data table when no student.
  noData: boolean = false;            // Showing No Student Message, when no student in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)
  mode: String = "Defaut";
  downloadURL: any;
  localUserData: LocalUser;
  closeModal: string;

  constructor(
    private router: Router,
    public fb: FormBuilder,
    //public authservice: AuthService,
    public parametersApi: ParametersService,
    private toastr: ToastrService,    // Toastr service for alert message
    private storage: AngularFireStorage,
    private modalService: NgbModal,

  ) {  //console.log(Date() ,'constructor')
  }


  ngOnInit(): void {
    //console.log(Date() ,'ngoninit')

    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.localUserData.role > 2 ? {} : this.router.navigate(['Welcome'])
    }

   
    //this.updateParametersData();
   

 
    //console.log(Date() ,'school',this.schoolYearList)
    this.dropdownSettings = {
      "singleSelection": true,
      "defaultOpen": false,
      "idField": "id",
      "textField": 'completeSchoolYear',
      "selectAllText": "Select All",
      "unSelectAllText": "UnSelect All",
      "enableCheckAll": false,
      "itemsShowLimit": 12,
      "allowSearchFilter": true,
      "searchPlaceholderText": 'Rechercher une année scolaire'
    };

    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      //console.log(Date() ,data.payload.toJSON())
      //console.log(Date() ,this.ParametersForm)
      this.ParametersForm.setValue(data.payload.toJSON())  // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 

    })
    this.updateParametersData();   // Call updateStudentData() as soon as the component is ready
  }







  onItemSelect(item: any) {

  }

  onSelectAll(items: any) {
    //console.log(Date() ,'onSelectAll', items);
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  // Go back to previous component

  onBack() {
    this.router.navigate(['admin-colleagues-list']);

  }



  get schoolName() {
    return this.ParametersForm.get('schoolName');
  }

  get schoolYear() {

    return this.ParametersForm.get('schoolYear');
  }

  get schoolLogo() {
    return this.ParametersForm.get('schoolLogo');
  }



  get coverSheetPicture() {
    return this.ParametersForm.get('coverSheetPicture');
  }

  get globalQuota() {
    return this.ParametersForm.get('globalQuota');
  }

  get emailSuffix() {
    return this.ParametersForm.get('emailSuffix');
  }


  get calculateTotalInPrint() {
    return this.ParametersForm.get('calculateTotalInPrint');
  }

  get TCType() {
    return this.ParametersForm.get('TCType');
  }

  get TCDecisions() {
    return this.ParametersForm.get('TCDecisions');
  }
  get pivotDate() {
    return this.ParametersForm.get('pivotDate');
  }

  /*get PDFJoin() {
   return this.ParametersForm.get('PDFJoin');
  }*/

  /*get moderatorEmailAddress() {
    return this.ParametersForm.get('moderatorEmailAddress');
  }
*/
  updateParametersData() {
    this.ParametersForm = this.fb.group({
      calculateTotalInPrint: [],
      TCType: [''],
      TCDecisions: [''],
      coverSheetPicture: [''],
      globalQuota: ['', [Validators.pattern('^[0-9]+$')]],
      infoPage: [''],
      pivotDate: ['',[Validators.required, Validators.minLength(2)]],
      moderatorEmailAddress: ["", [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-zA-Z\-0-9]+[\.]+[a-zA-Z]{2,}$')]],
      schoolLogo: [''],
      schoolName: ['', [Validators.pattern('^[A-Za-z0-9\-\ ]+$')]],

    })
  }

  ResetForm() {

    this.ParametersForm.reset()

    let parameters = {

      TCDecisions: true,
      TCType: false,
      calculateTotalInPrint: false,
      coverSheetPicture: "assets/images/Collaboratif.png",
      globalQuota: 60,
      infoPage: "Entrez votre texte ici",
      moderatorEmailAddress: "bosscool@bosscool.be",
      pivotDate:"2023-07-08",
      schoolLogo: "assets/images/LogoBosscool.png",
      schoolName: "Boss School",

    }
    //console.log(Date() ,parameters)
    this.ParametersForm.setValue(parameters)
   //console.log(Date() ,this.ParametersForm)
  }

  submitParametersData() {

    if(this.ParametersForm.controls['globalQuota'].value<1){
      this.ParametersForm.controls['globalQuota'].setValue(1);
    }

    this.parametersApi.updateParameters(this.ParametersForm.value);
   
    this.toastr.success('Les paramètres ont été sauvegardés');
  };

  imgChangeLogo: any = "";
  imgChangeCover: any = "";
  cropImgPreviewLogo: any = "";
  cropImgPreviewCover: any = "";

  onCoverChange(event: any): void {
    this.mode = "Cover";
    //console.log(Date() ,this.mode);
    this.imgChangeCover = event;

  }

  onLogoChange(event: any): void {
    this.mode = "Logo";
    //console.log(Date() ,this.mode);
    this.imgChangeLogo = event;

  }

  cropImgLogo(e: ImageCroppedEvent) {

    this.cropImgPreviewLogo = e.base64

  }

  cropImgCover(e: ImageCroppedEvent) {

    this.cropImgPreviewCover = e.base64

  }

  cancel() {

    this.mode = "Defaut"

  }


  submitLogo() {

    //console.log(Date() ,this.mode);
    //console.log(Date() ,'Bouton VAlider')
    let Logo = base64ToFile(this.cropImgPreviewLogo);
    //let generatedImage = window.URL.createObjectURL(File);
    //console.log(Date() ,Logo)
    //this.onFileSelected(File)

    //window.open(generatedImage);
    const filePath = `Parameters/schoolLogo.jpg`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, Logo);


    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              //this.fb = url;
              //console.log(Date() ,url.toString());
              this.ParametersForm.controls['schoolLogo'].setValue(url.toString())
              //console.log(Date() ,this.ParametersForm.controls['schoolLogo'].value)
            }
            //console.log(Date() ,this.fb);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          //console.log(Date() ,url);
        }
      });
    this.mode = "Defaut"
  }

  submitCover() {

    //console.log(Date() ,this.mode);
    //console.log(Date() ,'Bouton VAlider')
    let Cover = base64ToFile(this.cropImgPreviewCover);
    //let generatedImage = window.URL.createObjectURL(File);
    //console.log(Date() ,Cover)
    //this.onFileSelected(File)
    //window.open(generatedImage);
    const filePath = `Parameters/coverSheetPicture.jpg`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, Cover);


    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              //this.fb = url;
              //console.log(Date() ,url.toString());
              this.ParametersForm.controls['coverSheetPicture'].setValue(url.toString())
              //console.log(Date() ,this.ParametersForm.controls['coverSheetPicture'].value)
            }
            //console.log(Date() ,this.fb);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          //console.log(Date() ,url);
        }
      });
    this.mode = "Defaut"

  }
  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }

  triggerModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), error.message)
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
