import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progressbar-labels',
  templateUrl: './progressbar-labels.component.html',
  styleUrls: ['./progressbar-labels.component.css']
})
export class ProgressbarLabelsComponent implements OnInit {

  constructor() { //console.log(Date() ,'constructor')
  }


  ngOnInit(): void {
   //console.log(Date() ,'ngoninit')
  }

}
