import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from './components/boilerplate/local-user';
import { IdleTimeoutManager } from "idle-timer-manager";



@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  localUserData: LocalUser;
  timer: any;



  constructor(
    public router: Router,
    public afAuth: AngularFireAuth,
  ) {
    //console.log(Date(), 'constructor')
  }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean | UrlTree {


    this.timer = new IdleTimeoutManager({

      timeout: 1200, // expired after 20 min


      onExpired: () => {
        this.router.navigate(['autologoff']);
      }
    });
 
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));

      if (this.localUserData.emailVerified) {
        if (route.routeConfig.path == 'bosscool') {
          //quand on clique sur l'icone bosscool
          this.router.navigateByUrl('terms-of-use')
          return this.checkUserLogin(route)
        }

        else {
          return this.checkUserLogin(route)
        }

      }

      else{
        this.router.navigateByUrl('')
        return false
      }


    }

    else {

      if (route.routeConfig.path == 'bosscool') {
        //quand on clique sur l'icone bosscool
        this.router.navigateByUrl('welcome')
        return false
      }

      else {
        this.router.navigateByUrl('')
        return false
      }
      
    }

  }


 checkUserLogin(route: ActivatedRouteSnapshot): boolean {
  
    if (this.localUserData.isLogged) {
 
    
//console.log(Number((this.localUserData.role).toString().replace(',','.')))
//console.log(Number(route.data.role))
      if (Number((this.localUserData.role).toString().replace(',','.'))<Number(route.data.role)) {
        this.router.navigate(['welcome']);

        return false;
      }
      return true;
    }
  }

  


}