import { Component, OnInit } from '@angular/core';
import { TravcolsService } from 'src/app/components/Travcols/travcols.service';
import { TravCol } from 'src/app/components/Travcols/travcol';
import { Router } from "@angular/router";
import { AuthService } from 'src/app/components/boilerplate/auth.service';
import { ParametersService } from '../../others/parameters.service';
import { Parameters } from '../../others/parameters';
import * as CryptoJS from 'crypto-js';
import { ColleagueOfTravcol } from '../colleague-of-travcol';
import { LocalUser } from '../../boilerplate/local-user';

@Component({
  selector: 'app-travcols-print',
  templateUrl: './travcols-print.component.html',
  styleUrls: ['./travcols-print.component.css']
})
export class TravColsPrintComponent implements OnInit {

  public travcol: TravCol[];                 // Save travcols data in TravCol's array.
  todayDate: Date = new Date();


  parameters: Object;
  localUserData: LocalUser;

  public userQuotaInMinutes;
  public colleaguesOfTravcol: ColleagueOfTravcol[];
  preLoader: boolean = true;
   hasValidatedFound: boolean = false;
  toBeDisplayed: boolean;
  noData: boolean = false;
  hideWhenNoTravCol: boolean = false;
  minDate: string;
  maxDate: string;
  pivotDate: Date = new Date();
  //limitDate = new Date (this.todayDate.getFullYear(),6,8)
  final: number = 0
  constructor(
    public parametersApi: ParametersService,
    public travcolsApi: TravcolsService, // Inject travcol CRUD services in constructor.
    public router: Router,
    public authservice: AuthService,
  ) {//console.log(Date() ,'constructor')


  }


  ngOnInit(): void {
    //console.log(Date() ,'ngoninit')


    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.userQuotaInMinutes = this.localUserData.quota * 50

    }


    //console.log(Date() , this.displayName)
    document.getElementById("footer").style.display = "none";

    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      //console.log(data.payload.toJSON()['pivotDate']);
      this.parameters = data.payload.toJSON()

      //console.log(Date() ,"pivotdate:" + this.parameters['pivotDate'])
      //console.log(Date() ,"params:" + this.parameters)

      let lPivotDate =new Date(this.parameters['pivotDate'])
      this.pivotDate=new Date (this.todayDate.getFullYear(),lPivotDate.getMonth(),lPivotDate.getDate())
      this.calculateMinMaxDate(this.pivotDate)
    })
    
    

    let s = this.travcolsApi.GetTravColsList(this.localUserData.emailID);
    s.valueChanges().subscribe(sData => {
      this.dataState(sData); // Initialize travcol's list, when component is ready
      let travcols = {}
      sData.forEach(sElement => {
        travcols = { ...travcols, ...sElement }
      });
      this.travcol = [];

      Object.values(travcols).forEach(travcol => {
        this.colleaguesOfTravcol = [];

        Object.values(travcol['colleaguesOfTravcol']).forEach(colleaguesOfTravcol => {
          colleaguesOfTravcol['key'] = colleaguesOfTravcol['colleagueEmailID']




          this.colleaguesOfTravcol.push(colleaguesOfTravcol as ColleagueOfTravcol)
          if (colleaguesOfTravcol['colleagueEmailID'] == travcol['userEmailID'] && colleaguesOfTravcol['hasValidated'] == true) {
            this.toBeDisplayed = true
          }

        });
        travcol["colleaguesOfTravcol2"] = this.colleaguesOfTravcol
        if (this.toBeDisplayed) {

          this.travcol.push(travcol as TravCol);
          this.toBeDisplayed = false
        }
      });
      this.dataState(this.travcol)
    });
  }

  public calculateMinMaxDate(pivotDate: Date) {
    let lMinDate: Date = new Date();
    let lMaxDate: Date = new Date();

    if (this.todayDate > pivotDate) {
      lMinDate = new Date(Number(this.todayDate.getFullYear()), this.pivotDate.getMonth(), this.pivotDate.getDate())
      lMaxDate = new Date(Number(this.todayDate.getFullYear()) + 1, this.pivotDate.getMonth(), this.pivotDate.getDate())

    }
    else {
      lMinDate = new Date(Number(this.todayDate.getFullYear()) - 1, this.pivotDate.getMonth(), this.pivotDate.getDate())
      lMaxDate = new Date(Number(this.todayDate.getFullYear()), this.pivotDate.getMonth(), this.pivotDate.getDate())
    }

    this.minDate = ("0" + lMinDate.getDate()).slice(-2) + '-' + ("0" + (lMinDate.getMonth() + 1)).slice(-2) + '-' + lMinDate.getFullYear();
    this.maxDate = ("0" + lMaxDate.getDate()).slice(-2) + '-' + ("0" + (lMaxDate.getMonth() + 1)).slice(-2) + '-' + lMaxDate.getFullYear() ;
  }

  // Using valueChanges() method to fetch simple list of travcols data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState(data) {

    this.preLoader = false;
    if (data.length <= 0) {
      this.hideWhenNoTravCol = false;
      this.noData = true;
    } else {
      this.hideWhenNoTravCol = true;
      this.noData = false;
    }

  }



  PrintPage() {



    var css = '@page { size: landscape;  size: 100%;margin:0}',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');


    style.media = 'print';

    style.appendChild(document.createTextNode(css));


    head.appendChild(style);


    window.print();

  }

  //sortBy(prop: string) {
  //  return this.TravCol.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);

  //}

  //Return the Current School Year

  giveSchoolYear() {
    let init = 0;
    //let final = 0;
    let SchoolYear = "";
    let month = this.todayDate.getMonth() + 1;
    //let limitDate = new Date (this.todayDate.getFullYear(),6,8)
    if (this.todayDate > this.pivotDate) {
      init = Number(this.todayDate.getFullYear());
      this.final = Number(this.todayDate.getFullYear()) + 1;


    }
    else {
      init = Number(this.todayDate.getFullYear()) - 1;
      this.final = Number(this.todayDate.getFullYear());
    }

    SchoolYear = init.toString() + '-' + this.final.toString();
    //console.log(Date() ,month)
    //console.log(Date() ,"year",SchoolYear);
    return SchoolYear
  }




  getTotal(travcol: TravCol[]) {

    let myObject = [];

    myObject['totalMinute'] = 0;
    myObject['totalPourcent'] = 0;
    myObject['totalPeriode'] = 0;

    if (travcol != null) {
      travcol.forEach(element => {

        //console.log(Date() ,element['colleaguesOfTravcol'][element.userEmailID]['hasActivated'])
        if (element['colleaguesOfTravcol'][element.userEmailID]['hasActivated'] == true && element.isActivable == true) {


          myObject['totalMinute'] += Number(element['duration'])
        }

      });

    }

    myObject['totalPeriode'] = myObject['totalMinute'] / 50;
    myObject['totalPeriode'] = Number(myObject['totalPeriode'].toFixed(1));
    myObject['totalPourcent'] = Math.round(myObject['totalMinute'] / (this.localUserData.quota / 2));




    return myObject;

  }

}




