import { Component, OnInit } from '@angular/core';
import { AuthService } from "../auth.service"
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametersService } from '../../others/parameters.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.dev';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit {
  Form: FormGroup;
  token: string;
  suffix: string;
  localUserData: any;

  constructor(
    //public parametersApi: ParametersService,
    public authservice: AuthService,
    private router: Router,             // Router service to navigate to specific component
    private formBuilder: FormBuilder,
  ) { //console.log(Date() ,'constructor') 
  }


  ngOnInit(): void {
   //console.log(Date() ,'ngoninit')

   if (localStorage.getItem('userData') !== null ) {
    this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
   if (this.localUserData.isLogged){
     this.router.navigate(['terms-of-use']);
   }
   }




    this.Form = this.formBuilder.group(
      {
        emailLeft: ["", [Validators.required]],
        emailRight: ["", [Validators.required, Validators.pattern('^[a-zA-Z\-0-9]+[\.]+[a-zA-Z]{2,}$')]],
        password: ["", [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-/.€<>µ£~§={}]).{8,}$')]],
        //mdp min 8 caracteres + 1 MAJ + 1 caract special
        email2: ["", [Validators.required, Validators.pattern('ReCaptchaChecked')]],
        emailConfirm: [[]]
      },
    );
   // this.parametersApi.GetSuffix().valueChanges().subscribe(data => {

      this.Form.controls['emailRight'].setValue(environment.suffix)


   // })

  }

  /* permet de lire les champs*/
  get f() {

    return this.Form.controls;

  }

  onRecaptchaSuccess(token: string) {

    this.Form.controls['email2'].setValue('ReCaptchaChecked');
    //console.log(Date() ,this.f.emailConfirm.value)
    if (this.f.emailConfirm.value != "") {
      this.Form.controls['password'].setValue("")
      token = ""
    }
    //console.log(Date() ,token)
    this.token = token;
    //console.log(Date() ,this.token)


  }



  onSubmit() {

    this.authservice.SignIn(this.f.emailLeft.value + "@" + this.f.emailRight.value, this.f.password.value)
  }

  //window.location.reload()
  //console.log(Date() ,'signin,',this.authservice.isLoggedIn)
  //if(this.authservice.isLoggedIn) {
  //  this.router.navigate(['profile']);

}

