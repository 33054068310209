
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'; // Reactive form services
import { ToastrService } from 'ngx-toastr'; // Alert message using NGX toastr
import { Router } from '@angular/router';
import { TravcolsService } from 'src/app/components/Travcols/travcols.service';
import { FavoritesService } from 'src/app/components/favorites/favorites.service';
import { Favorites } from 'src/app/components/favorites/favorites';
//import { globalUserData } from 'src/app/app.component';
import { TravCol } from 'src/app/components/travcols/travcol'
import { ColleagueOfTravcol } from '../colleague-of-travcol';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ParametersService } from '../../others/parameters.service';
import { Observable } from 'rxjs';
import { TravcolsToBeValidatedComponent } from '../travcols-to-be-validated/travcols-to-be-validated.component';


@Component({
  selector: 'app-add-travcol',
  templateUrl: './add-travcol.component.html',
  styleUrls: ['./add-travcol.component.css']
})
export class AddTravColComponent implements OnInit {



  myForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  dropdownSettings: any = {};

  public favorites: Favorites[];                 // Save allcolleagues data in TravCol's array.
  public travcolForm: FormGroup;  // Define FormGroup to TravCol's form

  newColleagueOfTravcol: ColleagueOfTravcol;
  newTravcol: TravCol;
  closeModal: string;
  hasFavorites: boolean = false;
  localUserData: LocalUser
  userEmailID: string
  parameters: Object;
  selectedFile: FileList | null;

  downloadURL: Observable<string>;
  uploadProgress: Observable<number>;
  todayDate: Date = new Date();
  public pivotDate: Date = new Date();
  //public pivotDate = new Date(this.todayDate.getFullYear(), 6, 8)
  minDate: string;
  maxDate: string;
  strTodayDate: string;
 
  constructor(
    public travcolsApi: TravcolsService,  // CRUD API services
    public favoritesApi: FavoritesService,
    public fb: FormBuilder,       // Form Builder service for Reactive forms
    public toastr: ToastrService,  // Toastr service for alert message
    public router: Router,
    private modalService: NgbModal,
    public parametersApi: ParametersService,

  ) {//console.log(Date() ,'constructor')
  }




  ngOnInit() {
    this.strTodayDate = this.todayDate.getFullYear() + '-' + ("0" + (this.todayDate.getMonth() + 1)).slice(-2) + '-' + ("0" + this.todayDate.getDate()).slice(-2);
    //console.log(this.strTodayDate)
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.userEmailID = this.localUserData.emailID
    }



    //console.log(Date() ,this.userEmailID)
    this.travcolsApi.GetTravColsList(this.localUserData.emailID);  // Call GetTravColsList() before main form is being called
    this.TraCoForm();              // Call TravCol form when component is ready



    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      this.parameters = data.payload.toJSON()
      //console.log(Date() ,this.parameters)
      if (!this.parameters['TCType']) {
        this.travcolForm.controls['typeOfTC'].disable()
      }
      let lPivotDate =new Date(this.parameters['pivotDate'])
      this.pivotDate=new Date (this.todayDate.getFullYear(),lPivotDate.getMonth(),lPivotDate.getDate())

      if (!this.parameters['TCDecisions']) {
        this.travcolForm.controls['decisions'].disable()
      }
      /*if (!this.parameters['PDFJoin']) {
        this.travcolForm.controls['PDFJoin'].disable()
      }*/
    })


    let s = this.favoritesApi.GetFavoritesList(this.userEmailID);
    s.snapshotChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
      let i = 0;
      this.favorites = [];
      //console.log(Date() ,data)
      data.forEach(item => {
        if (item['key'] == this.userEmailID) {
          this.favorites.splice(i, 1)
        }
        let a = item.payload.toJSON();
        //console.log(Date() ,a)
        this.favorites.push(a as Favorites)

        this.favorites.sort((n1, n2) => {
          if (n1['fullName'] > n2['fullName']) {
            return 1;
          }
          if (n1['fullName'] < n2['fullName']) {
            return -1;
          }
          return 0;
        });

        i++;
      })
      //console.log(Date() ,this.favorites.length)
      //console.log(Date() ,this.favorites)
      if (this.favorites.length > 0) {

        this.hasFavorites = true
      }
      else {
        this.hasFavorites = false
      }
      //console.log(Date() , this.hasFavorites)
    })



    this.dropdownSettings = {
      "singleSelection": false,
      "defaultOpen": false,
      "idField": "colleagueEmailID",
      "textField": 'fullName',
      "selectAllText": "Select All",
      "unSelectAllText": "UnSelect All",
      "enableCheckAll": false,
      "itemsShowLimit": 12,
      "allowSearchFilter": true,
      "searchPlaceholderText": 'Rechercher un(e) collègue'
    };
  }




  public calculateMinMaxDate(pivotDate: Date) {
    let lMinDate: Date = new Date();
    let lMaxDate: Date = new Date();


    if (this.todayDate > pivotDate) {
      
      lMinDate = new Date(Number(this.todayDate.getFullYear()), this.pivotDate.getMonth(), this.pivotDate.getDate())
      lMaxDate = new Date(Number(this.todayDate.getFullYear()) + 1, this.pivotDate.getMonth(), this.pivotDate.getDate())

    }
    else {
      lMinDate = new Date(Number(this.todayDate.getFullYear()) - 1, this.pivotDate.getMonth(), this.pivotDate.getDate())
      lMaxDate = new Date(Number(this.todayDate.getFullYear()), this.pivotDate.getMonth(), this.pivotDate.getDate())
    }

    this.minDate = lMinDate.getFullYear() + '-' + ("0" + (lMinDate.getMonth() + 1)).slice(-2) + '-' + ("0" + lMinDate.getDate()).slice(-2);
    this.maxDate = lMaxDate.getFullYear() + '-' + ("0" + (lMaxDate.getMonth() + 1)).slice(-2) + '-' + ("0" + lMaxDate.getDate()).slice(-2);
    //console.log('min: ' + this.minDate + ' max : ' + this.maxDate)
  }


  public minMaxDateValidator(control: FormControl) {
    this.calculateMinMaxDate(this.pivotDate)
    if (new Date(control.value) < new Date(this.minDate)) {
      return "false"
    }
    else if (new Date(control.value) > new Date(this.maxDate) ) {
      return "false"
    }
    else {
      return null
    }

  }

  onItemSelect(item: any) {
    //console.log(Date() ,item)
    //console.log(Date() ,this.travcolForm.value.colleaguesOfTravcol)
  }


  onSelectAll(items: any) {
    //console.log(Date() ,'onSelectAll', items);
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  triggerModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), "triggerModal failed: " + error.message)
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  // Reactive TravCol form
  TraCoForm() {
    this.travcolForm = this.fb.group({
      userEmailID: this.userEmailID,
      colleagueEmailID: '',
      key: '',
      date: ['', [Validators.required,Validators.minLength(2), this.minMaxDateValidator.bind(this)]],
      duration: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      colleaguesOfTravcol: ['', [Validators.required]],
      goals: ['', [Validators.required, this.noWhitespaceValidator]],
      decisions: ['', [Validators.required, this.noWhitespaceValidator]],
      initials: this.localUserData.initials,
      isActivable: [false, []],
      //isActivable: [true, []],
      ownerID: this.userEmailID,
      typeOfTC: ['', [Validators.required, this.noWhitespaceValidator]],
      //PDFJoin: ['', [Validators.required]]
    })
  }



  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }



  // Accessing form control using getters
  /*get PDFJoin() {
    return this.travcolForm.get('PDFJoin');
  }*/
  get date() {
    return this.travcolForm.get('date');
  }

  get duration() {
    return this.travcolForm.get('duration');
  }

  get colleaguesOfTravcol() {
    return this.travcolForm.get('colleaguesOfTravcol');
  }

  get goals() {

    return this.travcolForm.get('goals');
  }

  get typeOfTC() {

    return this.travcolForm.get('typeOfTC');
  }

  get decisions() {
    return this.travcolForm.get('decisions');
  }

  get isActivable() {
    return false;
  }


  // Reset TravCol form's values
  ResetForm() {
    this.travcolForm.reset();

  }

  submitTravColData() {





    this.travcolForm.value.colleaguesOfTravcol.push({
      colleagueEmailID: this.userEmailID,
      fullName: this.localUserData.displayName,

    });

    var travcolCustomKey = this.travcolsApi.CreateTravcolKeyRef({});




    this.newTravcol = this.travcolForm.value

    if (this.travcolForm.controls['typeOfTC'].value == '') {
      this.newTravcol['typeOfTC'] = 'NoData';
    }
    if (this.travcolForm.controls['decisions'].value == '') {
      this.newTravcol['decisions'] = 'NoData';
    }
    //console.log('new', this.newTravcol)


    var objNewTravcol = Object.assign({}, this.newTravcol)

    objNewTravcol['creationDate'] = new Date().toLocaleString('fr-BE', { timeZone: 'Europe/Brussels' })
    objNewTravcol['colleaguesListString'] = ""


    this.travcolForm.value.colleaguesOfTravcol.forEach(
      element3 => {
        objNewTravcol['colleaguesListString'] = objNewTravcol['colleaguesListString'] + ' ' + element3['fullName']
      })

    this.createTravcols(travcolCustomKey, objNewTravcol)

    this.toastr.success('Le travail collaboratif du ' + this.travcolForm.controls['date'].value.split('-').reverse().join('-') + ' a été ajouté avec succès !'); // Show success message when data is successfully submited
    this.ResetForm();  // Reset form when clicked on reset button
    this.router.navigate(['travcols-list']);
    // }

  };


  detectFiles(event) {
    this.selectedFile = event.target.files[0];
  }


  createTravcols(travcolCustomKey, objNewTravcol) {
    this.travcolForm.value.colleaguesOfTravcol.forEach(
      element => {

        objNewTravcol['colleagueEmailID'] = element['colleagueEmailID']
        objNewTravcol['key'] = travcolCustomKey
        objNewTravcol['colleaguesOfTravcol'] = ''
        objNewTravcol['userEmailID'] = this.userEmailID
        objNewTravcol['fullName'] = this.localUserData.displayName
        this.travcolsApi.AddTravColWithCustomKey(travcolCustomKey, objNewTravcol)


        this.travcolForm.value.colleaguesOfTravcol.forEach(element2 => {
          var newColleagueOfTravcol = {
            userEmailID: "Updated by Travcolservice",
            colleagueEmailID: element2['colleagueEmailID'],
            travcolKey: travcolCustomKey,
            hasValidated: false,
            hasActivated: true,
            fullName: element2.fullName,

          }
          if (element2['colleagueEmailID'] == this.userEmailID) {
            newColleagueOfTravcol.hasValidated = true
          }
          this.travcolsApi.AddColleagueOfTravcol(objNewTravcol, newColleagueOfTravcol)
        });
      })
  }

  uploadFile(objNewTravcol) {

  }



}