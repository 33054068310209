import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',

})
export class SortPipe implements PipeTransform {


  transform(items: any, mode: string, column: string, type: string) {

    //console.log('Sort' + mode, column, type)


    let sortedItems = [];
    switch (mode) {
      case 'asc':
        sortedItems = this.sortAscending(items, column, type)
        return sortedItems;


      case 'dsc':
        sortedItems = this.sortDescending(items, column, type)
        return sortedItems;
      default:
        return items;
    }

  }


  sortAscending(items, column, type) {
    //console.log(items)
    //console.log(column)
    //console.log(type)
    if (items) {
      if (type == 'number') {
        return items.sort((a, b) => Number(a[column]) > Number(b[column]) ? 1 : Number(a[column]) === Number(b[column]) ? 0 : -1)
      }
      //console.log(Date() ,JSON.stringify(this.colleagues[1][this.sort]))
      return items.sort((a, b) => a[column] > b[column] ? 1 : a[column] === b[column] ? 0 : -1);
    }
  }

  sortDescending(items, column, type) {
    //console.log(items)
    //console.log(column)
    //console.log(type)
    if (items) {
      if (type == 'number') {
        return items.sort((a, b) => Number(a[column]) < Number(b[column]) ? 1 : Number(a[column]) === Number(b[column]) ? 0 : -1)
      }
      //console.log(Date() ,JSON.stringify(this.colleagues[1][this.sort]))
      return items.sort((a, b) => a[column] < b[column] ? 1 : a[column] === b[column] ? 0 : -1);
    }
  }

}