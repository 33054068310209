<header class="page-header" id="header">
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom"
  style="margin-top: 100px; margin-bottom: 10px;">
  <h1 style="color: white; margin-right: 25px;">Liste des travaux collaboratifs </h1>
  <!-- It won't show if there is no travcol data -->
  <a routerLink="/add-travcol" class="btn btn-secondary" style="margin-bottom: 15px; margin-right: 30px;"
    *ngIf="hideWhenNoTravCol &&localUserData &&!localUserData.isInvite">
    Ajouter un TC
    <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span
        class="path2"></span></span>
  </a>
  <a routerLink="/travcols-print" class="btn btn-secondary" style="margin-bottom: 15px;padding-top: 8px;"
    *ngIf="hideWhenNoTravCol && localUserData &&!localUserData.isInvite">
    Imprimer les TC
    <span class="icon-iconPrint" style="font-size: 1.5em;"><span class="path1"></span><span class="path2"></span></span>

  </a>

</div>

<div class="pricing-header mx-auto">

  <!-- Preloader shows before the data loads-->
  <div class="no-data text-center" *ngIf="preLoader">
    <img src="assets/preloader.svg" class="preloader-icon" alt="No travcol">
  </div>

  <!-- No data shows when their is no travcol data available -->
  <div class="no-data text-center" *ngIf="noData">
    <img src="assets/images/NoTravcol2.png" width="30%" height="30%" class="nodata-msg" alt="">
    <p style="color: white; ">Pas de travail collaboratif à lister</p>
    <a *ngIf="localUserData &&!localUserData.isInvite" routerLink="/add-travcol" class="btn btn-secondary"
      style="margin-bottom: 25px;margin-top: -10px;">
      Ajouter un TC
      <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>

    </a>
  </div>


  <div class="wrapper" style="min-width: 100%;" *ngIf="hideWhenNoTravCol">
    <div class="cards_wrap" style="min-width: 100%;">
      <div class="card_item" style="min-width: 100%;margin-bottom: 20px;">
        <div class="card_inner" style="min-width: 100%;">
          <div style="text-align: right;margin-bottom: -30px;">



            <button ngbTooltip="Aide" class="btn" (click)="triggerModal(modalDataTravColListe)">
              <span class="icon-iconInfo" style="font-size: 1.4em;"></span>
            </button>
          </div>
          <div class="card_top center" style="min-width: 100%;">
          </div>
          <div class="card_bottom" style="min-width: 100%;">
            <div class="card_info" style="min-width: 100%;">
              <!-- Showing travcols data -->

              <div *ngIf="localUserData &&!localUserData.isInvite">
                <table scrollx="true" style="margin-left:auto;margin-right:auto;min-width: 100%;margin-bottom: 20px;">


                  <img
                    *ngIf="getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes/5]"
                    src="./assets/images/ProgressBar1.png" style="width: 100%;" />
                  <img
                    *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*2/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes*2/5]"
                    src="./assets/images/ProgressBar2.png" style="width: 100%;" />
                  <img
                    *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes*2/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*3/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes*3/5]"
                    src="./assets/images/ProgressBar3.png" style="width: 100%;" />
                  <img
                    *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes*3/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*4/5] || getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes*4/5]"
                    src="./assets/images/ProgressBar4.png" style="width: 100%;" />
                  <img
                    *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes*4/5] && getTotal(travcol)['totalMinute']&lt;[this.userQuotaInMinutes*5/5]"
                    src="./assets/images/ProgressBar5.png" style="width: 100%;" />
                  <img
                    *ngIf="getTotal(travcol)['totalMinute']&gt;[this.userQuotaInMinutes*5/5]|| getTotal(travcol)['totalMinute'] ==[this.userQuotaInMinutes*5/5]"
                    src="./assets/images/ProgressBar6.png" style="width: 100%;" />

                  Vous êtes à {{getTotal(travcol)['totalMinute']}} minute<span
                    *ngIf="getTotal(travcol)['totalMinute']&gt;1">s</span> ou {{getTotal(travcol)['totalPeriode']}}
                  période<span *ngIf="getTotal(travcol)['totalPeriode']&gt;1">s</span> de 50 minutes sur un total
                  demandé
                  de
                  {{this.userQuotaInMinutes/50}} périodes.
                  <div class="progress" style="background-color: #5b6570; margin-top: 10px;">
                    <div class="progress-bar progress-bar-striped progress-primary" role="progressbar"
                      [ngStyle]="{ 'width': getTotal(travcol)['totalPourcent']+'%'}" aria-valuemin="0"
                      aria-valuemax="100"> </div>
                  </div>
                </table>
              </div>

              <br>

              <div class="card_creator" style="margin-top: 10px;">
                <!-- Pagination -->
                <pagination-controls class="my-pagination" (pageChange)="p = $event" autoHide="true" responsive="true"
                  previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>

              </div>


              <div style="overflow-x:auto" id="pdfTable">
                <table scrollx="true" style="margin-left:auto;margin-right:auto;min-width: 100%;margin-bottom: 20px;">

                  <tr>
                    <th class="center">
                      <span *ngIf="!this.viewSearch['date']"> Date </span>
                      <span *ngIf="!this.viewSearch['date']" (click)="startFilter('date')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['date']" [(ngModel)]="searchTerm['date']" class="search"
                        placeholder="Chercher une date">


                      <span *ngIf="this.viewSearch['date']" (click)="endFilter('date')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'date', type: 'string', searchTerm: searchTerm['date']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='date' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='date' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'date', type: 'string', searchTerm: searchTerm['date']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='date' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='date' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>


                    <th class="center">
                      <span *ngIf="!this.viewSearch['duration']"> Durée </span>
                      <span *ngIf="!this.viewSearch['duration']" (click)="startFilter('duration')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['duration']" [(ngModel)]="searchTerm['duration']" class="search"
                        placeholder="Chercher une durée">


                      <span *ngIf="this.viewSearch['duration']" (click)="endFilter('duration')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'duration', type: 'number', searchTerm: searchTerm['duration']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='duration' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='duration' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'duration', type: 'number', searchTerm: searchTerm['duration']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='duration' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='duration' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>

                    <th class="center" *ngIf="parameters['TCType']">

                      <span *ngIf="!this.viewSearch['colleaguesListString']"> Collègues </span>
                      <span *ngIf="!this.viewSearch['colleaguesListString']" (click)="startFilter('colleaguesListString')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['colleaguesListString']" [(ngModel)]="searchTerm['colleaguesListString']" class="search"
                        placeholder="Chercher un type de TC">


                      <span *ngIf="this.viewSearch['colleaguesListString']" (click)="endFilter('colleaguesListString')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'colleaguesListString', type: 'string', searchTerm: searchTerm['colleaguesListString']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='colleaguesListString' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='colleaguesListString' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'colleaguesListString', type: 'string', searchTerm: searchTerm['colleaguesListString']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='colleaguesListString' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='colleaguesListString' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center" *ngIf="parameters['TCType']">

                      <span *ngIf="!this.viewSearch['typeOfTC']"> Type </span>
                      <span *ngIf="!this.viewSearch['typeOfTC']" (click)="startFilter('typeOfTC')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['typeOfTC']" [(ngModel)]="searchTerm['typeOfTC']" class="search"
                        placeholder="Chercher un type de TC">


                      <span *ngIf="this.viewSearch['typeOfTC']" (click)="endFilter('typeOfTC')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'typeOfTC', type: 'string', searchTerm: searchTerm['typeOfTC']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='typeOfTC' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='typeOfTC' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'typeOfTC', type: 'string', searchTerm: searchTerm['typeOfTC']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='typeOfTC' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='typeOfTC' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>

                    <th class="center">
                      <span *ngIf="!this.viewSearch['goals']"> Objectifs </span>
                      <span *ngIf="!this.viewSearch['goals']" (click)="startFilter('goals')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['goals']" [(ngModel)]="searchTerm['goals']" class="search"
                        placeholder="Chercher un objectif">


                      <span *ngIf="this.viewSearch['goals']" (click)="endFilter('goals')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'goals', type: 'string', searchTerm: searchTerm['goals']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='goals' && mode =='asc')" class="icon-iconTriHaut"
                          style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='goals' && mode =='asc'" class="icon-iconTriHautCouleur"
                          style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'goals', type: 'string', searchTerm: searchTerm['goals']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='goals' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='goals' && mode =='dsc'" class="icon-iconTriBasCouleur"
                          style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span class="path1"></span><span
                            class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center" *ngIf="parameters['TCDecisions']">

                      <span *ngIf="!this.viewSearch['decisions']"> Décisions </span>
                      <span *ngIf="!this.viewSearch['decisions']" (click)="startFilter('decisions')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['decisions']" [(ngModel)]="searchTerm['TCDecisions']" class="search"
                        placeholder="Chercher une décision">


                      <span *ngIf="this.viewSearch['decisions']" (click)="endFilter('decisions')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                          style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                            class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                      <span
                        (click)="setSortParams({mode: 'asc', column: 'decisions', type: 'string', searchTerm: searchTerm['TCDecisions']})">
                        <span ngbTooltip="A -> Z" *ngIf="!(column=='decisions' && mode =='asc')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="column=='decisions' && mode =='asc'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span
                        (click)="setSortParams({mode: 'dsc', column: 'decisions', type: 'string', searchTerm: searchTerm['TCDecisions']})">
                        <span ngbTooltip="Z -> A" *ngIf="!(column=='decisions' && mode =='dsc')" class="icon-iconTriBas"
                          style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="column=='decisions' && mode =='dsc'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                    </th>
                    <th class="center"><span *ngIf="!this.viewSearch['initials']" ngbTooltip="Géniteur"
                        class="icon-iconProfil" style="font-size: 1.4em;"><span class="path1"></span><span
                          class="path2"></span></span>

         
                            <span *ngIf="!this.viewSearch['initials']" (click)="startFilter('initials')">
                              <span ngbTooltip="Filtrer" class="icon-iconFiltreOk"
                                style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                                  class="path2"></span><span class="path3"></span></span>
                            </span>
      
                            <input *ngIf="this.viewSearch['initials']" [(ngModel)]="searchTerm['initials']" class="search"
                              placeholder="Chercher une initiale">
      
      
                            <span *ngIf="this.viewSearch['initials']" (click)="endFilter('initials')">
                              <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"
                                style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span
                                  class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                            </span>
                            <span
                              (click)="setSortParams({mode: 'asc', column: 'initials', type: 'string', searchTerm: searchTerm['initials']})">
                              <span ngbTooltip="A -> Z" *ngIf="!(column=='initials' && mode =='asc')"
                                class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                              <span ngbTooltip="A-> Z" *ngIf="column=='initials' && mode =='asc'"
                                class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                  class="path1"></span><span class="path2"></span></span>
                            </span>
      
      
                            <span
                              (click)="setSortParams({mode: 'dsc', column: 'initials', type: 'string', searchTerm: searchTerm['initials']})">
                              <span ngbTooltip="Z -> A" *ngIf="!(column=='initials' && mode =='dsc')"
                                class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                              <span ngbTooltip="Z -> A" *ngIf="column=='initials' && mode =='dsc'"
                                class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                  class="path1"></span><span class="path2"></span></span>
                            </span>
      
                          </th>                   
                          <th class="center"><span class="icon-iconInscription" style="font-size: 1.3em;"><span
                          class="path1"></span><span class="path2"></span></span></th>
                    <th *ngIf="localUserData &&!localUserData.isInvite" class="center"><span
                        ngbTooltip="Comptabilisation" class="icon-IconHorloge" style="font-size: 1.4em;"><span
                          class="path1"></span><span class="path2"></span></span></th>

                  </tr>

                  <tbody *ngIf="hideWhenNoTravCol">
                    <!-- *ngFor loop iterates over travcol array and fetch the travcol's data -->
                    <!-- paginate pipe will add pagination in travcol's list, it won't show if items are less then 7 -->
                    <tr *ngFor="let travcol of travcol
                    |filter:column:searchTerm[column]
                    |sort:mode:column:type
                    |paginate: { itemsPerPage: 7, currentPage: p };                 
                     let i = index;">
                      <td class="center" style="font-weight: bold;" [ngStyle]="{
                        'color'
                        : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                        : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable)? 'var(--disabled)' 
                        : 'white' 
                      }">
                        {{travcol.date |
                        date:'dd/MM/yyyy'}}</td>
                      <td class="center" style="font-weight: bold;" [ngStyle]="{
                          'color'
                          : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                        {{travcol.duration}}</td>
                      <td style="font-weight: bold;">

                        <ng-container *ngFor="let colleagues of travcol['colleaguesOfTravcol2'] ">

                          <li *ngIf="travcol.colleaguesOfTravcol[colleagues.key]" [ngStyle]="{
                          'color'
                          : !travcol.isActivable ? 'var(--disabled)'
                          : travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] && !travcol.colleaguesOfTravcol[colleagues.key]['hasActivated'] ? 'var(--disabled)' 
                          : !travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] && (!travcol.colleaguesOfTravcol[colleagues.key]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                            {{colleagues.fullName}}
                            <!-- =IA:{{travcol.isActivable}},HA:{{colleagues.hasActivated}},HV:{{colleagues.hasValidated}}-->
                          </li>
                        </ng-container>
                      </td>
                      <td *ngIf="parameters['TCType']" style="font-weight: bold; ;" [ngStyle]="{
                        'color'
                        : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                        : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                        : 'white' 
                      }">
                        {{travcol.typeOfTC}}
                      </td>

                      <td style="font-weight: bold; ;" [ngStyle]="{
                          'color'
                          : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                        {{travcol.goals}}
                      </td>
                      <td style="font-weight: bold;" *ngIf="parameters['TCDecisions']" [ngStyle]="{
                          'color'
                          : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                        {{travcol.decisions}}</td>
                      <td class="center" style="font-weight: bold;" [ngStyle]="{
                          'color'
                          : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                        <a
                          [ngbTooltip]="(travcol.ownerID==localUserData.emailID)?travcol.fullName +' (Propriétaire)':travcol.fullName">{{travcol.initials}}</a>
                      </td>
                      <td class="center">
                        <!-- routerLink="/edit-travcol/{{travcol.key}}" is refered to { path: 'edit-travcol/:id', component: EditTravColComponent } in app-routing.moudles.ts -->

                        <!--<a style="color: transparent;" href={{travcol.PDFJoin}}>
                          <span ngbTooltip="Pièce jointe"
                          *ngIf="parameters['PDFJoin']&&travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']"
                          class="icon-iconPDF" style="font-size: 1.5em;cursor:pointer"></span>
                         </a> -->

                        <span ngbTooltip="Modifier"
                          *ngIf="travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']&&travcol.ownerID==localUserData.emailID"
                          class="icon-iconEdit" style="font-size: 1.5em;cursor:pointer"
                          [routerLink]="['/edit-travcol',travcol.userEmailID,travcol.ownerID,travcol.key]"></span>


                        <!--span class="icon-iconSupprimer"style="font-size: 1.5em;" (click)="deleteTravCol(travcol)"><span class="path1"></span><span class="path2"></span></span-->




                        <span [ngbTooltip]="(travcol.ownerID==localUserData.emailID)?'Suprimer': 'Se retirer'"
                          *ngIf="travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']"
                          class="icon-iconSupprimer" style="font-size: 1.5em;cursor:pointer"
                          (click)="deleteChoice(modalChoice,travcol)"><span class="path1"></span><span
                            class="path2"></span></span>


                      </td>


                      <td *ngIf="localUserData &&!localUserData.isInvite" class="center">
                        <button ngbTooltip="Décompter"
                          *ngIf="travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']&&travcol.isActivable"
                          class="icon-iconOwner btn" style="font-size: 1.4em; padding: none; margin:none"
                          (click)="toggleHasActivated(travcol)">
                          <span class="path1"></span><span class="path2"></span></button>
                        <button ngbTooltip="Compter"
                          *ngIf="!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']&&travcol.isActivable"
                          class="icon-iconNotOwner btn" style="font-size: 1.4em; padding: none; margin:none"
                          (click)="toggleHasActivated(travcol)">
                          <span class="path1"></span><span class="path2"></span></button>
                        <button ngbTooltip="Compter" *ngIf="!travcol.isActivable" class="icon-iconNotOwner btn"
                          style="font-size: 1.4em; padding: none; margin:none" (click)="triggerModal(modalActivation)">
                          <span class="path1"></span><span class="path2"></span></button>
                      </td>

                    </tr>
                  </tbody>


                </table>

                <div class="d-flex justify-content-center  align-items-center">
                  <a routerLink="/travcols-print" class="btn btn-secondary"
                    style="margin-bottom: 15px;padding-top: 8px;" *ngIf="hideWhenNoTravCol">
                    Imprimer les TC
                    <span class="icon-iconPrint" style="font-size: 1.5em;"><span class="path1"></span><span
                        class="path2"></span></span>

                  </a>
                </div>
                <!--<div> <button  (click)="downloadAsPDF()">Export To PDF</button></div>   -->
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>



<ng-template #modalChoice let-modal>
  <div class="modal-header">
    <div class="modal-title " style="font-size: 15pt;margin: auto;" id="modal-basic-title">Effacement des TC</div>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;">

    <p><strong>Quand un TC vous appartient, vous l'effacez pour tous
        les collègues qui y sont liés et pour vous-même. En d'autres termes, le TC disparaît. </strong> </p>
    <p><strong>Par contre, quand un TC ne vous appartient pas, vous vous retirez de la liste des collègues de ce TC.
      </strong> </p>
    <p><strong>Et dans ce cas-ci, ce TC <span *ngIf="!this.isOwnerOfTC">ne vous appartient pas </span> <span
          *ngIf="this.isOwnerOfTC">vous appartient </span> donc : </strong> </p>



    <ul style="padding-left: 25px;">
      <li *ngIf="!isOwnerOfTC"> Si vous voulez <strong>vous retirer</strong> ce TC mais que pour vous seul, veuillez
        cliquer sur "Se retirer".Et tant pis pour votre quota! Na!</li>
      <li *ngIf="isOwnerOfTC"> Si vous voulez <strong>effacer ce TC </strong> (vos collègues et vous-même), veuillez
        cliquer sur "Effacer".</li>
      <li> Si ne ne voulez rien faire, veuillez cliquer sur "Annuler". </li>
    </ul>





  </div>
  <div class="modal-footer" style="font-size: 12pt; margin-top: -20px;">


    <button *ngIf="!isOwnerOfTC" type="button" class="btn btn-danger" style="font-size: 12pt;"
      (click)="modal.close('Effacer')">Se retirer
      <span class="icon-iconSupprimer" style="font-size: 1.5em;"><span class="path1"></span><span
          class="path2"></span></span>
    </button>

    <button *ngIf="isOwnerOfTC" type="button" class="btn btn-danger" style="font-size: 12pt;"
      (click)="modal.close('Effacer')">Effacer
      <span class="icon-iconExterminate" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span><span class="path3"></span></span>
    </button>

    <button type="button" class="btn btn-danger" style="font-size: 12pt;" (click)="modal.close('Annuler')">Annuler
      <span class="icon-iconRetour" style="font-size: 1.3em;"></span>
    </button>
  </div>
</ng-template>


<ng-template #modalDataTravColListe let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Pas de panique !</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;margin-bottom: -20px;">

    Dans cette liste, vous trouvez les travaux collaboratifs que soit :
    <ul style="padding-left: 35px;">
      <li>vous avez encodés (sauf pour les invités),
      <li>vos collègues ont encodés pour vous et que vous avez acceptés avec amour. </li>
    </ul>
    Vous trouverez aussi les initiales de votre collègue adoré(e) qui a encodé le travail pour vous.
    <br>
    Si vous n'êtes pas un invité, vous pouvez soit :
    <ul style="padding-left: 35px;">
      <li>modifier <span class="icon-iconEdit" style="font-size: 1.5em;"></span>
        ce travail si vous en êtes le propriétaire, </li>
      <li>vous retirer <span class="icon-iconSupprimer" style="font-size: 1.4em;"><span class="path1"></span><span
            class="path2"></span></span> de ce travail si vous n'en êtes pas le propriétaire,
      </li>
      <li>supprimer <span class="icon-iconSupprimer" style="font-size: 1.4em;"><span class="path1"></span><span
            class="path2"></span></span> ce travail si vous en êtes le propriétaire (il s'effacera
        alors chez tous vos collègues),
      </li>
      <li>
        compter
        <span class="icon-iconNotOwner" style="font-size: 1.4em; padding: none; margin:none">
          <span class="path1" style="color:#73808d"></span><span class="path2"></span></span>
        ou décompter
        <span class="icon-iconOwner" style="font-size: 1.4em; padding: none; margin:none">
          <span class="path1"></span><span class="path2"></span></span>
        un travail.
      </li>
    </ul>

    Code couleurs :

    <ul style="padding-left: 35px;">
      <li>
        Tant qu'un de vos TC n'a pas été accepté par au moins un de vos collègues, celui-ci reste totalement <strong
          style="color:#73808d">Gris</strong> et ne sera pas comptabilisé chez vous.
      </li>
      <li> <strong style="color:var(--fushia)"> Fushia</strong> : TC pas encore accepté par le collègue,
      </li>
      <li> <strong> Blanc</strong> : TC accepté et comptabilisé par le collègue,
      </li>

      <li> <strong style="color:#73808d"> Gris</strong> : TC accepté mais non comptabilisé chez le collègue.
      </li>
      ... comme ça, vous savez tout, héhé !

    </ul>
    <span style="text-decoration: underline;"> Remarques</span> :
    <br>
    <ul style="padding-left: 35px;">
      <li>
        Un TC compté fera avancer Expresso l'escargot alors qu'un TC non comptabilisé le laissera triste et immobile,
      </li>
      <li> Il est possible de filtrer <span class="icon-iconFiltreOk" style="font-size: 1.4em;"><span
            class="path1"></span><span class="path2"></span><span class="path3"></span></span> certaines colonnes de la
        liste.

      </li>
      <li>C'est en cliquant sur <span class="icon-iconPrint" style="font-size: 1.5em;"><span class="path1"></span><span
            class="path2"></span></span> que vous pourrez imprimer vos TC. <strong> Attention </strong> : le carnet est
        prévu pour être
        imprimé en orientation paysage. Veillez à conserver ce mode.

      </li>


    </ul>



  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Fermer
      <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>
    </button>
  </div>



</ng-template>


<ng-template #modalActivation let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Comptabilisation d'un Travail collaboratif en attente</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">x</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;">

    Aucun de vos collègues n'a accepté ce travail collaboratif, il n'est donc pas comptabilisable pour le moment.<br>
    ... et puis quoi encore ? Manquerait plus que ça !

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Fermer
      <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>
    </button>
  </div>
</ng-template>