import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })



export class FilterSearchPipe implements PipeTransform {


  transform(items: any, column: string, searchTerm) {

    //console.log('Filter' + column + searchTerm)

    let filteredItems = []
    let filter = {}
    if (searchTerm != undefined) {
      //console.log(searchTerm)
      filter[column] = searchTerm
      filteredItems = this.filter(items, filter)
      return filteredItems;

    }
    return items
  }


  filter(items: any, filter: any): any {
    //console.log(items)
    //console.log(filter)
    //console.log(defaultFilter)
    if (!filter) {
      return items;
    }

    if (!Array.isArray(items)) {
      return items;
    }

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);
      //console.log(filterKeys)


      return items.filter(item => {
        return filterKeys.some((keyName) => {
          return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
        });
      });

    }
  }




  sortAscending(items, column, type) {
    //console.log(items)
    //console.log(column)
    //console.log(type)
    if (items) {
      if (type == 'number') {
        return items.sort((a, b) => Number(a[column]) > Number(b[column]) ? 1 : Number(a[column]) === Number(b[column]) ? 0 : -1)
      }
      //console.log(Date() ,JSON.stringify(this.colleagues[1][this.sort]))
      return items.sort((a, b) => a[column] > b[column] ? 1 : a[column] === b[column] ? 0 : -1);
    }
  }

  sortDescending(items, column, type) {
    //console.log(items)
    //console.log(column)
    //console.log(type)
    if (items) {
      if (type == 'number') {
        return items.sort((a, b) => Number(a[column]) < Number(b[column]) ? 1 : Number(a[column]) === Number(b[column]) ? 0 : -1)
      }
      //console.log(Date() ,JSON.stringify(this.colleagues[1][this.sort]))
      return items.sort((a, b) => a[column] < b[column] ? 1 : a[column] === b[column] ? 0 : -1);
    }
  }

}