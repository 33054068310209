import { Injectable } from '@angular/core';
import { Student } from './student';  // Student data type interface class
import { AngularFireList, AngularFireDatabase,AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object
import { AuthService } from '../boilerplate/auth.service';
//import { globalUserData } from 'src/app/app.component';


@Injectable({
  providedIn: 'root'
})
export class StudentsService {
 
  studentsRef: AngularFireList<any>;    // Reference to Student data list, its an Observable
  studentRef: AngularFireObject<any>;   // Reference to Student object, its an Observable too
  emailID="test";

  constructor(


    private db: AngularFireDatabase,
    public authservice: AuthService,
  ) {//console.log(Date() ,'constructor')
  }



  // Fetch Single Student Object
  GetStudent(id: string) {
    //this.studentRef = this.db.object('students-list/' + this.emailID + "/" + id);
    return this.studentRef;
  }

  // Create Student
  AddStudent(student: Student) {
    this.studentsRef.push({
      firstName: student.firstName,
      lastName: student.lastName,
      email: student.email,
      mobileNumber: student.mobileNumber
    })
  }

  
  // Fetch Students List
  GetStudentsList() {
    //this.studentsRef = this.db.list('students-list/' + this.emailID);
    return this.studentsRef;
  } 


  // Update Student Object
  UpdateStudent(student: Student) {
    this.studentRef.update({
      firstName: student.firstName,
      lastName: student.lastName,
      email: student.email,
      mobileNumber: student.mobileNumber
    })
  }  

    // Delete Student Object
    DeleteStudent(id: string) { 
      //this.studentRef = this.db.object('students-list/' + this.emailID + "/" +id);
      this.studentRef.remove();
    }

}