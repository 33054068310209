import { Component, OnInit } from '@angular/core';
import { ColleaguesService } from '../colleagues.service';  // CRUD API service class
import { ToastrService } from 'ngx-toastr';      // Alert message using NGX toastr
import { Colleague } from 'src/app/components/colleagues/colleague';
import { FavoritesService } from 'src/app/components/favorites/favorites.service';
import { AuthService } from 'src/app/components/boilerplate/auth.service';
import { ColleagueOfTravcol } from "src/app/components/Travcols/colleague-of-travcol";
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-colleagues-list',
  templateUrl: './colleagues-list.component.html',
  styleUrls: ['./colleagues-list.component.css']
})
export class ColleaguesListComponent implements OnInit {


  realRole = { 1: "Invité", 2: "Utilisateur", 3: "Administrateur", 4: "Super-Zéro" };
  p: number = 1;                      // Settup up pagination variable
  colleagues: Colleague[];                 // Save colleagues data in TravCol's array.
  hideWhenNoColleague: boolean = false; // Hide colleagues data table when no colleagues.
  noData: boolean = false;            // Showing No colleagues Message, when no colleagues in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)

  userEmailID: string

  colleagueOfTravcol: ColleagueOfTravcol;
  localUserData: LocalUser;
  closeModal: string;
  hideWhenNoTravCol: boolean = false;
  public allTravcols = [];                 // Save travcols data in travcol's array.
  public userEmailIdListAndTravcolIsActivable = [];
  totalPeriod: {}

  searchTerm = {};
  mode: string = "asc";
  column: string = "lastName";
  type: string = "string";
  public viewSearch = {
    "firstName": false,
    "lastName": false,
    "emailAddress": false,
    "isFavorite": false
  }




  constructor(
    public colleaguesApi: ColleaguesService, // Inject colleagues CRUD services in constructor.
    public toastr: ToastrService, // Toastr service for alert message
    public favoritesApi: FavoritesService,
    public authservice: AuthService,
    private modalService: NgbModal,




  ) {
    //console.log(Date(), 'constructor')
  }

  ngOnInit() {
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      this.userEmailID = this.localUserData.emailID
    }

    this.dataState(); // Initialize colleagues's list, when component is ready

    let collList = this.colleaguesApi.GetColleaguesList();
    collList.snapshotChanges().subscribe(colleaguesData => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
      this.colleagues = [];


      colleaguesData.forEach(item => {
        let colleague = item.payload.toJSON();
        //console.log(Date() ,colleague)
        colleague['colleagueEmailID'] = item.key;
        colleague['userEmailID'] = this.userEmailID
        item.payload.hasChild('isFavoriteOf/' + this.userEmailID) ? colleague['isFavorite'] = true : colleague['isFavorite'] = false
        colleague['realRole'] = this.realRole[colleague['role']]
        this.colleagues.push(colleague as Colleague);

      })


      //console.log(this.colleagues)
      let favoritesList = this.favoritesApi.GetFavoritesList(this.userEmailID);
      favoritesList.snapshotChanges().subscribe(favoritesData => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
        //console.log(Date(), favoritesData)
        favoritesData.forEach(item => {
          let favoriteColleague = item.payload.toJSON();

          //console.log(Date(), favoriteColleague)

          favoriteColleague['emailAddress'] = favoriteColleague['colleagueEmailID'].replaceAll("*", ".")

          const names = this.colleagues.map(colleague => colleague['emailAddress']);
          //console.log(names)
          //console.log(favoriteColleague['emailAddress'])
          if (!names.includes(favoriteColleague['emailAddress'])) { // 
            /*//console.log(favoriteColleague['fullName'])
            var fn = favoriteColleague['fullName'].split(' ')
            //console.log(fn)
            favoriteColleague['firstName'] = fn[0]
            //console.log(favoriteColleague['firstName'])
            favoriteColleague['lastName'] = fn[fn.length - 1]
            //console.log(favoriteColleague['lastName'])
            favoriteColleague['emailAddress']
            favoriteColleague['isFavorite'] = true
            this.colleagues.push(favoriteColleague as Colleague);*/
            this.favoritesApi.DeleteFavorite(favoriteColleague);
            this.colleaguesApi.DeleteIsFavoriteOf(favoriteColleague['colleagueEmailID']);

          }

        });
      })
    })


  }




  // Using valueChanges() method to fetch simple list of colleagues data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState() {
    this.colleaguesApi.GetColleaguesList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoColleague = false;
        this.noData = true;
      } else {
        this.hideWhenNoColleague = true;
        this.noData = false;
      }
    })
  }

  ToggleFavorite(choosenColleague: Colleague) {
    //console.log(Date() ,choosenColleague)
    if (choosenColleague.colleagueEmailID == this.userEmailID) {
      this.toastr.error(`Voyons, même si vous vous aimez narcissiquement, il n'est pas possible de vous "auto-ajouter" !`);
      return
    }
    //console.log(Date() ,this.colleagues)

    var myIndex = this.colleagues.findIndex(function (item) {
      //console.log(Date() ,item)
      if (item.colleagueEmailID == choosenColleague.colleagueEmailID) {
        //console.log(Date() ,item.colleagueEmailID)
      }
      return item.colleagueEmailID === choosenColleague.colleagueEmailID
    });



    if (!choosenColleague.isFavorite) {
      //console.log(Date() ,this.userEmailID)
      this.favoritesApi.AddFavorite(choosenColleague);
      this.colleaguesApi.AddIsFavoriteOf(choosenColleague.colleagueEmailID, choosenColleague.firstName + ' ' + choosenColleague.lastName);
      //console.log(Date() ,this.colleagues)
      //console.log(Date() ,myIndex)
      this.colleagues[myIndex].isFavorite = true;
      this.toastr.success('Le collègue ' + choosenColleague.firstName + ' ' + choosenColleague.lastName + ' a bien été ajouté dans vos favoris! On finira par tout savoir !');
    }

    else {
      this.favoritesApi.DeleteFavorite(choosenColleague);
      this.colleaguesApi.DeleteIsFavoriteOf(choosenColleague.colleagueEmailID);
      this.colleagues[myIndex].isFavorite = false;
      this.toastr.success("Voilà, " + choosenColleague.firstName + ' ' + choosenColleague.lastName + " n'est maintenant plus un de vos favoris! Tant pis pour votre amitié !");


    }


  }


  setSortParams(param) {
    let vsKeys = Object.keys(this.viewSearch)
    vsKeys.forEach(element => {
      //console.log(element)
      if (element != param.column) {
        this.viewSearch[element] = false
      }

    });
    //console.log(param)
    this.mode = param.mode;
    this.column = param.column;
    this.type = param.type;
    //this.searchTerm = thi
  }

  startFilter(column) {
    let vsKeys = Object.keys(this.viewSearch)
    vsKeys.forEach(element => {
      //console.log(element)
      this.viewSearch[element] = false
    });
    //console.log(column)
    this.mode = ""
    this.column = column;
   
    this.viewSearch[column] = true

  }

  endFilter(column) {
    this.searchTerm[column] = ""
    this.viewSearch[column] = false

  }


  triggerModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), error.message)
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }





}

