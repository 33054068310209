import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ParametersService } from '../../others/parameters.service';
import { AuthService } from "../auth.service";
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {

  Form: FormGroup;
  token: string;

  constructor(
    public parametersApi: ParametersService,
    public authservice: AuthService,
    private router: Router,             // Router service to navigate to specific component
    private formBuilder: FormBuilder,
  ) { //console.log(Date() ,'constructor') 
  }



  ngOnInit(): void {
    //console.log(Date() ,'ngoninit')
 
 
 
     this.Form = this.formBuilder.group(
       {
         emailLeft: ["", [Validators.required]],
         emailRight: ["", [Validators.required, Validators.pattern('^[a-zA-Z\-0-9]+[\.]+[a-zA-Z]{2,}$')]],
         email2: ["", [Validators.required, Validators.pattern('ReCaptchaChecked')]],
         emailConfirm: [[]]
       },
     );

 //    this.parametersApi.GetSuffix().valueChanges().subscribe(data => {
     this.Form.controls['emailRight'].setValue(environment.suffix)
    // })
 
   }
 
   /* permet de lire les champs*/
   get f() {
 
     return this.Form.controls;
 
   }
 
   onRecaptchaSuccess(token: string) {
 
     this.Form.controls['email2'].setValue('ReCaptchaChecked');
     //console.log(Date() ,this.f.emailConfirm.value)
     if (this.f.emailConfirm.value != "") {
       this.Form.controls['password'].setValue("")
       token = ""
     }
     //console.log(Date() ,token)
     this.token = token;
     //console.log(Date() ,this.token)
 
 
   }
 
 
 
   onSubmit() {
    //console.log(this.f.emailLeft.value + "@" + this.f.emailRight.value)
    this.authservice.ForgotPassword(this.f.emailLeft.value + "@" + this.f.emailRight.value)
    // this.authservice.SignIn(this.f.emailLeft.value + "@" + this.f.emailRight.value, this.f.password.value)
    this.router.navigate(['sign-in'])
   }
 
   //window.location.reload()
   //console.log(Date() ,'signin,',this.authservice.isLoggedIn)
   //if(this.authservice.isLoggedIn) {
   //  this.router.navigate(['profile']);
 
}